import React from "react";
import styles from './LoaderSearch.module.scss'
import { SearcherIcon } from "../../../icons";

const LoaderSearch = () => {
  return (
    <section className={styles.main} >
      <span className={styles.loader}><SearcherIcon/></span>
    </section>
  );
};

export default LoaderSearch;
