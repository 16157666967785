import React from "react";
import { IconProps } from "../../types/appTypes";

const BurgerIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "26"}
      height={height ? height : "18"}
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="25.0363" height="2" rx="1" fill={color ? color : "white"} />
      <rect
        y="8"
        width="15.0218"
        height="2"
        rx="1"
        fill={color ? color : "white"}
      />
      <rect
        y="16"
        width="25.0363"
        height="2"
        rx="1"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export default BurgerIcon;
