import React from "react";
import { IconProps } from "../../types/appTypes";

const Arrow: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.941169 0.5L-6.11962e-07 1.49995L6.11798 8.00003L6.18489e-05 14.5L0.941231 15.5L8 8.00037L0.941169 0.5Z"
        fill="#070707"
      />
    </svg>
  );
};

export default Arrow;
