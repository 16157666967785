import React from "react";

interface IncDecBtnProps {
  count: number;
  onInc: () => void;
  onDec: () => void;
  amount: number
}

const IncDecBtn: React.FC<IncDecBtnProps> = ({ count, onDec, onInc, amount }) => {
  return (
    <div className="flex flex-row items-center" >
      <button
        className={`dec__btn w-[30px] h-[30px] md:w-8 md:h-8 text-base font-semibold disabled:bg-strokeGrey bg-primary hover:bg-secondary duration-300 text-white rounded-full`}
        disabled={count <= 1 ? true : false}
        onClick={() => {
          onDec();
        }}
      >
        -
      </button>
      <span className="text-base font-semibold text-black px-[10px]">
        {count}
      </span>
      <button
        disabled={count>=amount?true:false}
        onClick={() => onInc()}
        className="inc__btn w-[30px] h-[30px] md:w-8 md:h-8 text-base font-semibold text-white disabled:bg-strokeGrey bg-primary hover:bg-secondary duration-300 rounded-full"
      >
        +
      </button>
    </div>
  );
};

export default IncDecBtn;
