import React from "react";
import { FieldErrorsImpl } from "react-hook-form";
import { errorDetail } from "../../../utils/errHandler";
import { EyeIcon, EyeSlashIcon } from "../../icons";

type inpType = "text" | "tel" | "password";

interface InputProps {
  inpId: string;
  inpClassName?: string;
  inpName: string;
  rootClassName?: string;
  readonly?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  value: string | undefined;
  defaultValue?: string;
  labelTxt?: string;
  placeholder?: string;
  inpType?: inpType;
  errors?: Partial<FieldErrorsImpl<any>>;
  formController?: any;

  readOnlyFuncForBtn?: any;
}

const Input: React.FC<InputProps> = ({
  inpId,
  inpName,
  onChange,
  value,
  defaultValue,
  disabled,
  inpClassName,
  labelTxt,
  readonly,
  rootClassName,
  placeholder,
  inpType,
  errors,
  formController,
  readOnlyFuncForBtn,
}): JSX.Element => {
  const [visiblePass, setVisiblePass] = React.useState<boolean>(false);
  const [isReadAble, setIsReadAble] = React.useState(readonly);

  if (inpType === "password") {
    return (
      <div className={`${rootClassName} max-w-[335px] md:max-w-[500px]`}>
        <div>
          <div className="flex flex-row justify-between">
            <label
              className="text-[#8C8C8C] font-normal text-sm py-2.5 pr-1"
              htmlFor={inpName}
            >
              {labelTxt}
            </label>
          </div>
          <div className="relative">
            {visiblePass ? (
              <button
                onClick={() => {
                  setVisiblePass(false);
                }}
                className="absolute bottom-2.5 left-0 text-base font-medium text-primary border-r border-primary pr-1.5 w-[45.25px] flex justify-center"
              >
                <EyeIcon />
              </button>
            ) : (
              <button
                onClick={() => {
                  setVisiblePass(true);
                }}
                className="absolute bottom-2.5 left-0 text-base font-medium text-primary border-r border-primary pr-1.5 w-[45.25px] flex justify-center"
              >
                <EyeSlashIcon />
              </button>
            )}
            <input
              type={visiblePass ? "text" : "password"}
              onChange={(event) => onChange(event.target.value)}
              disabled={disabled}
              name={inpName}
              className={`pl-14 w-full border-b bg-transparent py-2.5 border-b-primary text-base font-medium text-primery ${inpClassName}`}
              defaultValue={defaultValue}
              value={value}
              id={inpId}
              placeholder={placeholder}
              {...formController}
            />
          </div>
          <div
            className="err_msg"
            dangerouslySetInnerHTML={{
              __html: errorDetail(inpName, errors),
            }}
          ></div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`${rootClassName} max-w-[335px] md:max-w-[500px]`}>
        <div>
          <div className="flex flex-row justify-between">
            <label
              className="text-[#8C8C8C] font-normal text-sm py-2.5 pr-1"
              htmlFor={inpName}
            >
              {labelTxt}
            </label>
            {readonly && (
              <div className="text-sm">
                {!isReadAble && (
                  <button
                    className="mr-5 underline text-textGrey hover:text-primary active:text-secondary duration-200"
                    onClick={() => {
                      readOnlyFuncForBtn();
                      setIsReadAble((prev) => !prev);
                    }}
                  >
                    подтвердить
                  </button>
                )}
                <button
                  className="underline text-textGrey hover:text-primary active:text-secondary duration-200"
                  onClick={() => {
                    setIsReadAble((prev) => !prev);
                  }}
                >
                  {isReadAble ? "изменить" : "отменить"}
                </button>
              </div>
            )}
          </div>
          <div className="relative">
            {inpType === "tel" ? (
              <span className={`absolute bottom-2.5 left-0 text-base font-medium border-r pr-1.5 ${!isReadAble?"text-primary border-primary":"text-gray-300 border-gray-200"}`}>
                +992
              </span>
            ) : (
              <></>
            )}
            <input
              type={inpType ? inpType : "text"}
              onChange={(event) => onChange(event.target.value)}
              disabled={disabled}
              readOnly={isReadAble}
              name={inpName}
              className={`${
                inpType === "tel" ? "pl-14" : ""
              } w-full border-b bg-transparent py-2.5 border-b-primary text-base font-medium text-primery ${inpClassName} read-only:border-none read-only:text-gray-300 `}
              defaultValue={defaultValue}
              value={value}
              id={inpId}
              placeholder={placeholder}
              {...formController}
            />
          </div>
          <div
            className="err_msg"
            dangerouslySetInnerHTML={{
              __html: errorDetail(inpName, errors),
            }}
          ></div>
        </div>
      </div>
    );
  }
};

export default Input;
