import React from "react";
import { IconProps } from "../../../types/appTypes";

const PhoneIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "31"}
      height={height ? height : "31"}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 30.9658C23.7843 30.9658 30.5 24.2501 30.5 15.9658C30.5 7.68155 23.7843 0.96582 15.5 0.96582C7.21573 0.96582 0.5 7.68155 0.5 15.9658C0.5 24.2501 7.21573 30.9658 15.5 30.9658ZM8.62763 9.09966C8.22576 9.50113 8 10.0456 8 10.6134V12.219C8 18.4257 13.0371 23.4578 19.25 23.4578H20.8571C21.4255 23.4578 21.9705 23.2323 22.3724 22.8308C22.7742 22.4294 23 21.8849 23 21.3171V20.3388C23 19.7244 22.5814 19.1892 21.9857 19.0401L18.8264 18.2516C18.5707 18.1877 18.3018 18.2006 18.0534 18.2887C17.805 18.3768 17.5881 18.5361 17.43 18.7468L16.7371 19.6694C16.6643 19.7658 16.5593 19.7865 16.4857 19.7594C15.3894 19.3566 14.3938 18.7207 13.5678 17.8955C12.7419 17.0704 12.1053 16.0757 11.7021 14.9805C11.675 14.907 11.6957 14.8014 11.7921 14.7293L12.7157 14.0372C12.9266 13.8792 13.0861 13.6625 13.1743 13.4144C13.2625 13.1662 13.2754 12.8976 13.2114 12.6421L12.4221 9.48594C12.2729 8.89081 11.7371 8.47266 11.1229 8.47266H10.1429C9.57454 8.47266 9.02949 8.6982 8.62763 9.09966Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export default PhoneIcon;
