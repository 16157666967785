import React from "react";
import { IconProps } from "../../types/appTypes";

const CloseIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width?width:"25"}
      height={height?height:"25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0001 1.41509L23.585 0L12.5 11.0851L1.41509 0.000136446L0 1.41523L11.0849 12.5002L0.000203365 23.5849L1.41529 25L12.5 13.9153L23.5848 25.0001L24.9999 23.585L13.9151 12.5002L25.0001 1.41509Z"
        fill={color?color:"black"}
      />
    </svg>
  );
};

export default CloseIcon;
