import React from "react";
import { IconProps } from "../../types/appTypes";

const ArrowRightIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "38"}
      height={height ? height : "23"}
      viewBox="0 0 38 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25.7093 21.4146L35.7191 11.4146M35.7191 11.4146L25.7093 1.41455M35.7191 11.4146C35.7191 11.4146 10.6765 11.4146 1.68555 11.4146"
        stroke={color ? color : "#070707"}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ArrowRightIcon;
