import React from "react";
import { IconProps } from "../../../types/appTypes";

const TelegramIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "40"}
      height={height ? height : "41"}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40.9658C31.0457 40.9658 40 32.0115 40 20.9658C40 9.92013 31.0457 0.96582 20 0.96582C8.9543 0.96582 0 9.92013 0 20.9658C0 32.0115 8.9543 40.9658 20 40.9658ZM8.38416 19.6964L27.954 12.6605C28.9575 12.6605 29.4593 13.1295 29.4593 13.5986L25.9468 28.6087C25.9468 29.0777 24.9432 29.5468 24.4414 29.0777L19.4235 25.7943L16.4128 28.1396C15.902 28.5497 15.8794 28.5324 15.911 28.1396L16.4128 23.918L25.445 15.9439C25.9468 15.0058 24.9432 15.4749 24.9432 15.4749L13.4021 22.5108L8.38416 21.1036C7.88237 21.1036 7.88237 20.1655 8.38416 19.6964Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export default TelegramIcon;
