import React from "react";
import { Arrow, BurgerIcon } from "../../../icons";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { getAllCategories } from "../../../../api/appApi";

import type { MenuProps } from "antd";
import { Menu } from "antd";
import { categoryType } from "../../../../types/productTypes";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { createNestedStructure } from "../../../../utils/filterCatalog";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { setBurgerOpened } from "../../../../store/appSlice";

function renderMenuItems(
  items: categoryType[] | null,
  onClick?: MenuClickEventHandler | undefined
): JSX.Element[] | null {
  if (items) {
    return items.map((item: any) => {
      if (item.children?.length > 0) {
        return (
          <Menu.SubMenu
            // @ts-ignore
            onTitleClick={window.outerWidth > 700 ? onClick : () => {}}
            key={item.id}
            title={item.name}
          >
            {renderMenuItems(item.children)}
          </Menu.SubMenu>
        );
      } else {
        return <Menu.Item key={item.id}>{item.name}</Menu.Item>;
      }
    });
  } else {
    return null;
  }
}

const CatalogBtn: React.FC = (): JSX.Element => {
  const [menuVisible, setMenuVisible] = React.useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: categories, isLoading: isFetchingCategories } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories`,
        { params: {} }
      );
      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }
      return response.data;
    },
  });

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(`/products/bycategory/${e.key}`);
    setSelectedKeys([e.key]);
    setMenuVisible((prev) => !prev);
    dispatch(setBurgerOpened(false));
  };

  return (
    <>
      <div className="block md:hidden">
        <button
          title="Каталог"
          className={`flex flex-row items-center justify-between w-full ${
            menuVisible ? "catalogOpened" : ""
          } `}
          onClick={() => setMenuVisible((prev) => !prev)}
        >
          Каталог <Arrow />
        </button>
        {menuVisible && (
          <Menu
            selectable
            selectedKeys={selectedKeys}
            onClick={onClick}
            mode="vertical"
          >
            {renderMenuItems(createNestedStructure(categories), onClick)}
          </Menu>
        )}
      </div>
      <div
        className="hidden md:block md:relative w-[136.17px] h-10"
        onMouseEnter={() => setMenuVisible(true)}
      >
        <div className="w-[136.17px] h-10 ">
          <button
            title="Каталог"
            onClick={() => setMenuVisible((prev) => !prev)}
            className="md:absolute z-20 catalogBtn duration-500 md:py-[8px] md:px-[15px] md:bg-primary md:text-white md:hover:bg-secondary font-medium text-base flex flex-row items-center"
          >
            <BurgerIcon className="hidden md:inline-block mr-3.5" />
            Каталог
          </button>
        </div>
        {menuVisible && (
          <div className="md:absolute z-20">
            <Menu
              selectable
              selectedKeys={selectedKeys}
              onClick={onClick}
              mode="vertical"
              className="overflow-y-auto max-h-[450px]"
            >
              {renderMenuItems(createNestedStructure(categories), onClick)}
            </Menu>
          </div>
        )}
      </div>
      <div
        onClick={() => setMenuVisible(false)}
        onMouseEnter={() => {
          setMenuVisible(false);
        }}
        className={`w-full md:h-screen md:fixed top-0 left-0 right-0 bottom-0 ${
          menuVisible ? "block" : "hidden"
        } z-10`}
      />
    </>
  );
};

export default CatalogBtn;
