import React from "react";
import { IconProps } from "../../types/appTypes";

const CartIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "25"}
      height={height ? height : "26"}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6766 2.77197C10.8596 2.77197 9.32955 4.00057 8.87185 5.67236H16.4814C16.0237 4.00057 14.4937 2.77197 12.6766 2.77197ZM12.6766 0.499023C9.57739 0.499023 7.00778 2.7667 6.53653 5.73334C4.05782 6.09467 2.04686 8.02597 1.63738 10.5619L0.314024 18.7573C-0.257062 22.294 2.47391 25.5014 6.05642 25.5014H18.9435C22.3926 25.5014 25.0841 22.5173 24.7295 19.0865L23.8824 10.8911C23.6009 8.16734 21.4697 6.04403 18.814 5.71635C18.3357 2.75804 15.77 0.499023 12.6766 0.499023ZM7.37977 7.94531C5.64063 7.94531 4.15849 9.20732 3.88126 10.9242L2.55791 19.1197C2.20998 21.2744 3.8738 23.2284 6.05642 23.2284H18.9435C21.0448 23.2284 22.6846 21.4104 22.4686 19.3202L21.6215 11.1248C21.4348 9.31828 19.9126 7.94531 18.0964 7.94531H7.37977ZM10.1265 12.304C10.1265 12.9316 9.61765 13.4404 8.98999 13.4404C8.36233 13.4404 7.85352 12.9316 7.85352 12.304C7.85352 11.6763 8.36233 11.1675 8.98999 11.1675C9.61765 11.1675 10.1265 11.6763 10.1265 12.304ZM17.146 12.304C17.146 12.9316 16.6372 13.4404 16.0095 13.4404C15.3819 13.4404 14.873 12.9316 14.873 12.304C14.873 11.6763 15.3819 11.1675 16.0095 11.1675C16.6372 11.1675 17.146 11.6763 17.146 12.304Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export default CartIcon;
