import React, { LegacyRef } from "react";
import CatalogBtn from "../btns/CatalogBtn/CatalogBtn";
import {
  ArrowRightIcon,
  CloseIcon,
  EmptyBoxIcon,
  ImageIcon,
  SearcherIcon,
} from "../../icons";
import styles from "./Searcher.module.scss";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getAllProductsSearch } from "../../../api/productsApi";
import LoaderSearch from "../../ui/loaders/LoaderSearch/LoaderSearch";
import { Link, useNavigate } from "react-router-dom";
import { Button, Empty } from "antd";
import { BsArrowRight } from "react-icons/bs";

let searchTimer: NodeJS.Timeout;

const Searcher: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    searchedProducts,
    isErrSearchedProducts,
    isFetchingSearchedProducts,
  } = useAppSelector((state) => state.productsSlice);
  const [inputValue, setInputValue] = React.useState("");
  const [showResult, setShowResult] = React.useState<boolean>(false);
  const inputRef = React.useRef<any>();
  const inputMobileRef = React.useRef<any>();
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const clearInput = () => {
    setInputValue("");
    setShowResult(false);
    setTimeout(() => {
      inputRef.current!.focus({});
    }, 200);
  };
  const clearInputMobile = () => {
    setInputValue("");
    setShowResult(false);
    setTimeout(() => {
      inputMobileRef.current!.focus({});
    }, 200);
  };

  const onChangeInput = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
    clearTimeout(searchTimer);

    if (value.length > 0) {
      searchTimer = setTimeout(() => {
        dispatch(getAllProductsSearch({ search: value, page: 1, limit: 3 }));
      }, 720);
    }
    setShowResult(true);
  };

  const onSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowResult(false);
    navigate(`/productsSearch?value=${inputValue}`);
    setIsMobile(false);
  };

  return (
    <div className="block ">
      <div className="block md:hidden">
        <button
          title="Поиск"
          className="flex flex-row items-center bg-transparent p-0 m-0 focus:bg-transparent hover:bg-transparent"
          onClick={() => {
            setIsMobile((prev) => !prev);
            setTimeout(() => {
              inputMobileRef.current!.focus({
                cursor: "start",
              });
            }, 500);
          }}
        >
          <SearcherIcon color={isMobile ? "#f5016b" : ""} />
        </button>
        <div
          className={`flex w-full top-16 left-0 h-12 absolute ${
            isMobile ? "block" : "hidden"
          }`}
        >
          <form onSubmit={onSearch} className="flex w-full">
            <input
              // @ts-ignore
              ref={inputMobileRef}
              value={inputValue}
              type="search"
              name="searcher"
              id="searcher"
              placeholder="Найти в Hayat Beauty"
              onChange={onChangeInput}
              className={`w-full placeholder:text-strokeGrey pl-4 pr-4 text-sm font-medium bg-white border-b ${
                inputValue ? "border-b-secondary" : "border-b-primary"
              }`}
            />
          </form>

          {inputValue && (
            <button
              title="Очистить"
              onClick={clearInputMobile}
              className="absolute right-[15px] top-[15px] flex items-center"
            >
              <span className="overflow-hidden">
                <CloseIcon width="14" height="14" />
              </span>
            </button>
          )}

          {showResult && inputValue?.length ? (
            <div className="absolute top-[100%] w-full min-w-[300px] bg-[white] z-[99999] max-h-[80vh] overflow-hidden hover:overflow-y-scroll">
              {isFetchingSearchedProducts ? (
                <LoaderSearch />
              ) : searchedProducts?.length ? (
                <div className="bg-[#f9f9f9]">
                  {searchedProducts?.map((item) => (
                    <Link
                      state={{ productId: item.id }}
                      to={`/product/${item.id}/${item.good_id}`}
                      key={item.id}
                      // onClick={() => goToItemSearchPage(item)} key={item.id}
                      className="hover:bg-secondary hover:text-white duration-300 flex items-center gap-2.5 p-2.5 md:p-5 hover:opacity-[80%] hover:cursor-pointer"
                      onClick={() => {
                        setInputValue("");
                        setShowResult(false);
                        setIsMobile(false);
                      }}
                    >
                      {/* <Image src={item.img[0]} alt={item.title} width={80} height={80}></Image> */}
                      {item.images?.length ? (
                        <div>
                          <img
                            className="w-[50px] h-[70px]"
                            // src={item.images[0]?.image}
                            src={item.images[0]?.image}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="border border-strokeGrey w-[50px] h-[70px] flex justify-center items-center">
                          <ImageIcon className="w-8 h-8" />
                        </div>
                      )}
                      <div>
                        <h6 className="text-base font-medium">{item.name}</h6>
                        {item.volume ? (
                          <div className="flex flex-row items-center">
                            <span className="text-sm font-light mr-2.5">
                              объем:
                            </span>
                            <span className="text-xs font-extralight">
                              {item.volume}
                            </span>
                          </div>
                        ):""}
                        {item.color?.hex_value ? (
                          <div className="flex flex-row items-center">
                            <span className="font-light mr-2.5 text-sm">
                              цвет:
                            </span>{" "}
                            <div
                              style={{
                                border: "1px solid #1f1f1f",
                                width: 10,
                                height: 10,
                                borderRadius: "50%",
                                background: item.color?.hex_value,
                              }}
                            />
                            <span className="text-xs font-extralight ml-1.5">
                              {item.color?.name}
                            </span>
                          </div>
                        ):""}
                      </div>
                    </Link>
                  ))}
                  <Link
                    onClick={() => {
                      setShowResult(false);
                      setIsMobile(false);
                    }}
                    to={`/productsSearch?value=${inputValue}`}
                  >
                    <div className="flex flex-row py-3.5 px-5 justify-between bg-white">
                      <div>Все результаты поиска</div>
                      <div>
                        <BsArrowRight className="w-9 h-6" />
                      </div>
                    </div>
                  </Link>
                </div>
              ) : (
                <div className="py-4">
                  <Empty
                    description={
                      <p className="text-strokeGrey text-lg mt-2 font-semibold">
                        Пусто...
                      </p>
                    }
                    image={<EmptyBoxIcon width="85" height="85" />}
                  />
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="hidden md:flex flex-row h-[40px] justify-center">
        <CatalogBtn />
        <div className={`flex w-full max-w-[491px] relative`}>
          <form onSubmit={onSearch} className="flex w-full">
            <input
              // @ts-ignore
              ref={inputRef}
              value={inputValue}
              type="search"
              name="searcher"
              id="searcher"
              placeholder="Найти в Hayat Beauty"
              onChange={onChangeInput}
              className="searcher__inp max-w-[491px] w-full border-2 border-primary placeholder:text-strokeGrey pl-3 text-sm font-medium"
            />
          </form>
          {inputValue && (
            <button
              title="Очистить"
              onClick={clearInput}
              className="absolute right-[15px] top-[15px] flex items-center"
            >
              <span className="overflow-hidden">
                <CloseIcon width="14" height="14" />
              </span>
            </button>
          )}

          {showResult && inputValue?.length ? (
            <div className="absolute top-[100%] w-full min-w-[300px] bg-[white] z-[99999] max-h-[80vh] overflow-hidden hover:overflow-y-scroll">
              {isFetchingSearchedProducts ? (
                <LoaderSearch />
              ) : searchedProducts?.length ? (
                <div className="bg-[#f9f9f9]">
                  {searchedProducts?.map((item) => (
                    <Link
                      state={{ productId: item.id }}
                      to={`/product/${item.id}/${item.good_id}`}
                      key={item.id}
                      // onClick={() => goToItemSearchPage(item)} key={item.id}
                      className="hover:bg-secondary hover:text-white duration-300 flex items-center gap-2.5 p-2.5 md:p-5 hover:opacity-[80%] hover:cursor-pointer"
                      onClick={() => {
                        setInputValue("");
                        setShowResult(false);
                        setIsMobile(false);
                      }}
                    >
                      {/* <Image src={item.img[0]} alt={item.title} width={80} height={80}></Image> */}
                      {item.images?.length ? (
                        <div>
                          <img
                            className="w-[50px] h-[70px]"
                            src={item.images[0]?.image}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="border border-strokeGrey w-[50px] h-[70px] flex justify-center items-center">
                          <ImageIcon className="w-8 h-8" />
                        </div>
                      )}
                      <div>
                        <h6 className="text-base font-medium">{item.name}</h6>
                        {item.volume && (
                          <div className="flex flex-row items-center">
                            <span className="text-sm font-light mr-2.5">
                              объем:
                            </span>
                            <span className="text-xs font-extralight">
                              {item.volume}
                            </span>
                          </div>
                        )}
                        {item.color?.hex_value && (
                          <div className="flex flex-row items-center">
                            <span className="font-light mr-2.5 text-sm">
                              цвет:
                            </span>{" "}
                            <div
                              style={{
                                border: "1px solid #1f1f1f",
                                width: 10,
                                height: 10,
                                borderRadius: "50%",
                                background: item.color?.hex_value,
                              }}
                            />
                            <span className="text-xs font-extralight ml-1.5">
                              {item.color?.name}
                            </span>
                          </div>
                        )}
                      </div>
                    </Link>
                  ))}
                  <Link
                    onClick={() => {
                      setShowResult(false);
                      setIsMobile(false);
                    }}
                    to={`/productsSearch?value=${inputValue}`}
                  >
                    <div className="flex flex-row py-3.5 px-5 justify-between hover:bg-secondary hover:text-white duration-200">
                      <div>Все результаты поиска</div>
                      <div>
                        <BsArrowRight className="w-9 h-6" />
                      </div>
                    </div>
                  </Link>
                </div>
              ) : (
                <div className="py-4">
                  <Empty
                    description={
                      <p className="text-strokeGrey text-lg mt-2 font-semibold">
                        Пусто...
                      </p>
                    }
                    image={<EmptyBoxIcon width="85" height="85" />}
                  />
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          {showResult ? (
            <div
              className={styles.overlay}
              onClick={() => {
                setShowResult(false);
                setInputValue("");
                setIsMobile(false);
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <Link
          title="Поиск"
          onClick={() => setShowResult(false)}
          to={`/productsSearch?value=${inputValue}`}
          className="searcherBtn duration-500 bg-primary hover:bg-secondary w-[60px] h-[40px] flex justify-center items-center z-10"
        >
          <SearcherIcon color="#fff" />
        </Link>
      </div>
    </div>
  );
};

export default Searcher;
