import React from "react";
import { INavLinkType } from "../../../../types/appTypes";
import { Link } from "react-router-dom";
import "./Nav.scss";
import SubmitBtn from "../../../forms/btns/SubmitBtn/SubmitBtn";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { setVisibleLoginModalAC } from "../../../../store/userSlice";
import { ProfileIcon } from "../../../icons";
import CatalogBtn from "../../../forms/btns/CatalogBtn/CatalogBtn";
import { setBurgerOpened } from "../../../../store/appSlice";

const mainNavData: INavLinkType[] = [
  {
    id: 1,
    link: "/brands",
    text: "Бренды",
  },
  {
    id: 2,
    link: "/gifts",
    text: "Подарочные карты",
  },
  {
    id: 3,
    link: "/promotion",
    text: "Акции",
  },
  {
    id: 4,
    link: "/markets",
    text: "Магазины",
  },
];

const MobileMainNav: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { authorized } = useAppSelector((state) => state.userSlice);
  const { burgerOpened } = useAppSelector((state) => state.appSlice);

  return (
    <div className="flex justify-center md:mt-5 h-[25px]">
      <button
        className={`burger relative block md:hidden h-[25px] w-[25px] ${
          burgerOpened ? "burger__opened" : ""
        } `}
        onClick={() => {
          dispatch(setBurgerOpened(!burgerOpened));
        }}
      >
        <span className="burger__line"></span>
        <span className="burger__line"></span>
        <span className="burger__line"></span>
      </button>
      <nav
        className={`flex flex-col pt-5 nav main__nav burger__nav bg-white fixed w-screen h-screen top-[65px] left-0 px-5 ${
          burgerOpened ? "visible burger__nav--opened" : "invisible"
        }`}
      >
        <ul className="main__nav-menu flex flex-col md:flex-row h-[75vh]">
          <li className="main__nav-list_item py-1.5 main__nav-link text-base font-medium text-primary hover:text-secondary duration-300">
            <CatalogBtn />
          </li>
          {mainNavData.map((elem: INavLinkType) => (
            <li key={elem.id} className="main__nav-list_item py-1.5">
              <Link
                to={elem.link}
                className="main__nav-link text-base font-medium text-primary mr-20 hover:text-secondary duration-300"
                onClick={() => {
                  setBurgerOpened(false);
                }}
              >
                {elem.text}
              </Link>
            </li>
          ))}
        </ul>
        {authorized ? (
          <Link
            className="bg-primary border-none py-2.5 px-2 hover:bg-secondary duration-200 flex flex-row items-center"
            to={"/profile/me"}
            onClick={() => {
              dispatch(setBurgerOpened(false));
            }}
            title="Профиль"
          >
            <span>
              <ProfileIcon fill="#fff" color="#fff" />
            </span>
            <span className="text-white ml-2 font-medium tracking-wide">
              Профиль
            </span>
          </Link>
        ) : (
          <SubmitBtn
            className="w-full font-medium text-base"
            onClick={() => {
              dispatch(setVisibleLoginModalAC(true));
            }}
            txt="Войти или зарегистрироваться"
            tooltipTitle="Войти или зарегистрироваться"
            disabled={false}
            secondary={false}
          />
        )}
      </nav>
    </div>
  );
};

export default MobileMainNav;
