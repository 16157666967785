import React from "react";
import "./SubmitBtn.scss";
import { Spin } from "antd";

interface SubmitBtnProps {
  txt: string;
  onClick: (...arg: any) => any;
  disabled?: boolean;
  className?: string;
  secondary?: boolean;
  loading?: boolean;
  tooltipTitle?: string;
}

const SubmitBtn: React.FC<SubmitBtnProps> = ({
  className,
  onClick,
  txt,
  disabled,
  secondary,
  loading,tooltipTitle
}) => {
  return (
    <div>
      <button
        className={`${
          secondary ? "submitBtn_secondary" : "submitBtn_primary"
        } hover:bg-secondary ${className}`}
        onClick={onClick}
        disabled={disabled || loading}
        title={tooltipTitle}
      >
        {loading && <Spin />} {txt}
      </button>
    </div>
  );
};

export default SubmitBtn;
