import React from "react";
import MainNav from "../Navs/MainNav/MainNav";
import { FavoriteIcon, Logo, LogoWithTxt, ProfileIcon } from "../../icons";
import Searcher from "../../forms/Searcher/Searcher";
import HeaderRightNav from "../Navs/HeaderRightNav/HeaderRightNav";
import { Link, NavLink } from "react-router-dom";
import { Badge } from "antd";
import MobileMainNav from "../Navs/MainNav/MobileMainNav";
import "./Header.scss";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setVisibleLoginModalAC } from "../../../store/userSlice";

const Header: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { authorized } = useAppSelector((state) => state.userSlice);

  return (
    <>
      <div className="bg-white">
        <div className="container">
          <div className="header__top hidden md:block">
            <MainNav />
          </div>
        </div>
      </div>
      <header className="header bg-white sticky top-0 z-50">
        <div className=" w-full bg-white border-b border-b-strokeGrey py-3 md:pt-5 ">
          <div className="container px-3">
            <div className="header__bottom flex flex-row justify-between md:mb-5 items-center">
              <div className="block md:hidden">
                <MobileMainNav />
              </div>
              {authorized ? (
                <NavLink
                  title="Избранные"
                  to={"/favorites"}
                  className={({ isActive }) =>
                    isActive
                      ? "flex md:hidden w-[25px] active__fav_link"
                      : "flex md:hidden w-[25px] fav_link headerRightNav-link"
                  }
                >
                  <Badge count={0} overflowCount={99}>
                    <FavoriteIcon />
                  </Badge>
                </NavLink>
              ) : (
                <button
                  title="Избранные"
                  className="block md:hidden  fav_link headerRightNav-link"
                  onClick={() => dispatch(setVisibleLoginModalAC(true))}
                >
                  <FavoriteIcon />
                </button>
              )}
              <div className="logo__container mr-0">
                <Link to="/">
                  <span className="block md:hidden">
                    <Logo />
                  </span>
                  <span className="hidden md:block">
                    <LogoWithTxt />
                  </span>
                </Link>
              </div>
              <div className="searcher__container  md:mr-0 md:w-full px-0 md:px-2 lg:px4 w-[25px]">
                <Searcher />
              </div>
              <div className="headerRightNav-container">
                <HeaderRightNav />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
