import axios from "axios";
import store from "../store/store";
import { logoutAC, setTokensAC } from "../store/userSlice";
import { clearFavsAC } from "../store/favoritesSlice";
// import { logoutAC, setTokensAC } from "../store/usersSlice";

const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

$authHost.interceptors.request.use((config) => {
  const { accesstoken } = store.getState().userSlice;
  if (accesstoken) {
    config.headers.Authorization = `Bearer ${accesstoken}`;
  }
  return config;
});

$authHost.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const { refreshtoken } = store.getState().userSlice;
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/jwt/refresh`,
          {
            refresh: refreshtoken,
          }
          // {
          //   headers: {
          //     Authorization: `Bearer ${refreshtoken}`,
          //   },
          // }
        );
        if (response.status === 200) {
          store.dispatch(
            setTokensAC({
              token: response.data.accesstoken,
              refreshtoken: response.data.refreshtoken,
            })
          );
        }
        return $authHost.request(originalRequest);
      } catch (e) {
        store.dispatch(logoutAC());
        store.dispatch(clearFavsAC());
        // throw new Error("not authorized");
      }
    }
  }
);

export default $authHost;
