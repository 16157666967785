import React from "react";
import { ModalsType } from "../../../types/appTypes";
import { Modal } from "antd";
import SubmitBtn from "../../forms/btns/SubmitBtn/SubmitBtn";
import Input from "../../forms/Input/Input";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  changeEnterPassAC,
  changePhonNumAC,
  changeUserNameAC,
  setVisibleLoginModalAC,
  setVisibleSignUpModalAC,
} from "../../../store/userSlice";
import { checkPhoneForRegister } from "../../../api/userApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface signupType {
  userName: string;
  phoneNum: string;
  password: string;
}

const schema = yup.object({
  userName: yup
    .string()
    .required("обязательное поле")
    .min(3, "минимум 3 символа"),
  phoneNum: yup
    .string()
    .required("обязательное поле")
    .min(8, "минимум 8 символов")
    .max(24, "максимум 24 символов"),
  password: yup
    .string()
    .required("обязательное поле")
    .min(8, "минимум 8 символов")
    .max(20, "максимум 20 символов")
    .matches(/\d+/, "должен содержать хотяб одну цифру")
    .matches(/[a-z]+/, "не содержит нижний регистр (a-z)")
    .matches(/[A-Z]+/, "не содержит заглавные буквы (A-Z)")
    .matches(/[!@#$%^&*()-+]+/, "не содержит спец символы (!@#$%)")
    .test(
      "не должен содержать пробелы",
      "не должен содержать пробелы",
      (value) => !/\s+/.test(value)
    ),
});

const SignUpModal: React.FC<ModalsType> = ({ open, setOpen }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { phoneNum, userName, enterPass, fetchingCheckPhoneRes } =
    useAppSelector((state) => state.userSlice);

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    watch,
    clearErrors,
  } = useForm<signupType>({
    mode: "all",
    // resolver
    // resetOptions: {}
    // resolver: {password: {required: {value:true, }}}
    resolver: yupResolver(schema),
  });

  function signup(data: any) {
    dispatch(checkPhoneForRegister({ phone: phoneNum, is_send_sms: true }));
  }

  return (
    <Modal
      footer={null}
      open={open}
      onCancel={() => setOpen(false)}
      destroyOnClose={true}
    >
      <div className="pt-[50px] py-10 pb-10">
        <h4 className="mb-5 text-xl font-semibold md:text-[32px] text-primary">
          Зарегистрироваться
        </h4>
        <form onSubmit={handleSubmit(signup)}>
          <div>
            <div className="my-2.5">
              <Input
                inpId=""
                inpName="userName"
                onChange={(v) => {
                  dispatch(changeUserNameAC(v));
                  setValue("userName", v, { shouldValidate: true });
                }}
                value={userName}
                defaultValue=""
                disabled={false}
                inpClassName=""
                inpType="text"
                labelTxt="Имя Фамилия"
                errors={errors}
              />
            </div>
            <div className="my-5">
              <Input
                inpId=""
                inpName="phoneNum"
                onChange={(v) => {
                  dispatch(changePhonNumAC(v));
                  setValue("phoneNum", v, { shouldValidate: true });
                }}
                value={phoneNum}
                defaultValue=""
                disabled={false}
                inpClassName=""
                inpType="tel"
                labelTxt="Номер телефона"
                errors={errors}
              />
            </div>
            <div className="my-5">
              <Input
                inpId=""
                inpName="password"
                onChange={(v) => {
                  dispatch(changeEnterPassAC(v));
                  setValue("password", v, { shouldValidate: true });
                }}
                value={enterPass}
                defaultValue=""
                disabled={false}
                inpClassName=""
                inpType="text"
                labelTxt="Пароль"
                errors={errors}
              />
            </div>
            <div className="my-[30px]">
              <SubmitBtn
                className=""
                onClick={() => {
                  handleSubmit(signup);
                }}
                txt="Получить код"
                disabled={phoneNum && enterPass && userName ? false : true}
                loading={fetchingCheckPhoneRes}
              />
            </div>
          </div>
        </form>
        <div>
          <div className="text-sm mb-5">
            Уже зарегистрированы?{" "}
            <button
              className="text-secondary underline"
              onClick={() => {
                dispatch(setVisibleSignUpModalAC(false));
                dispatch(setVisibleLoginModalAC(true));
              }}
            >
              Войти
            </button>
          </div>
          <div className="mt-5 text-xs text-textGrey md:text-sm">
            <p>
              Нажимая «Получить код», вы принимаете условия
              <br />
              <Link className="underline" to="/">
                соглашения о конфиденциальности.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpModal;
