import {
  EmailIcon,
  InstagramIcon,
  LocationIcon,
  PhoneIcon,
  TelegramIcon,
} from "../../icons/SocialMediasIcons";
import { INavLinkType } from "../../../types/appTypes";
import { LogoWithTxt } from "../../icons";
import moment from "moment";
import './Footer.scss';

const footerContactsData: INavLinkType[] = [
  {
    id: 1,
    link: "https://goo.gl/maps/awwtYqJ6sWaMskKL9",
    text: "ул. Х. Дехлеви 2",
    icon: <span className="hidden md:block" >
    <LocationIcon />
    </span>
  },
  {
    id: 2,
    link: "mailto:hb.hayatbeauty@gmail.com",
    text: "hb.hayatbeauty@gmail.com",
    icon: <span className="hidden md:block" >
    <EmailIcon />
    </span>
  },
  {
    id: 3,
    link: "tel:+992940909009",
    text: "+992 940 90 90 09",
    icon: <span className="hidden md:block" >
    <PhoneIcon />
    </span>
  },
];

const Footer = () => {
  return (
    <footer className="footer w-full bg-primary py-5">
      <div className="container px-5">
        <div className="footer__top flex justify-center items-center md:justify-between flex-col text-white pb-4 md:flex-row">
          <div>
            <div className="mt-1 flex justify-center md:justify-start" >
              <LogoWithTxt color="#fff" />
            </div>
            <div className="my-3 md:my-5 max-w-sm">
              <p className="text-sm md:text-base font-normal md:font-medium text-center md:text-start">
                Онлайн-магазин
                <br />
                декоративной и уходовой косметики.
              </p>
            </div>
            <div className="social__medias flex flex-row my-4 justify-center md:justify-start">
              <a rel="noreferrer" target="_blank" href="https://www.instagram.com/hb.hayatbeauty/" className="mr-5 social-link">
                <InstagramIcon  />
              </a>
              <a rel="noreferrer" href="https://t.me/hbhayatbeauty" target="_blank" className="social-link" >
                <TelegramIcon />
              </a>
            </div>
          </div>
          <div className="footer__contacts">
            <h5 className="text-xl font-medium mb-5 hidden md:block">Контакты</h5>
            <ul className="space-y-5 flex flex-col items-center md:items-start md:items-strart" >
              {footerContactsData.map((elem: INavLinkType) => (
                <li key={elem.id}>
                  <a className="text-base md:text-lg font-normal flex flex-row items-center md:items-start hover:text-secondary duration-300" href={elem.link}>
                    {elem.icon}
                    <span className="md:ml-5">{elem.text}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="footer__bottom border-t text-xs font-normal md:text-sm border-strokeGrey flex justify-between flex-row text-strokeGrey mt-7 pt-2">
          <div>
            <time className="currentYear">© {moment().format("YYYY")}</time>
          </div>
          <div>
            <a href="/">Все права защищены</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
