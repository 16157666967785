import React from "react";
import { INavLinkType } from "../../../../types/appTypes";
import { FavoriteIcon, ProfileIcon } from "../../../icons";
import { Badge, Drawer, Empty, Modal } from "antd";
import CartContianer from "../../../containerComponents/CartContianer/CartContianer";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import SignUpModal from "../../../modals/SignUpModal/SignUpModal";
import {
  setVisibleCheckCodeModalAC,
  setVisibleCheckCodeModalResetPassAC,
  setVisibleLoginModalAC,
  setVisibleModalCodeResetPassAC,
  setVisibleModalPhoneAC,
  setVisibleModalResetPassAC,
  setVisibleSignUpModalAC,
} from "../../../../store/userSlice";
import LoginModal from "../../../modals/LoginModal/LoginModal";
import ConfirmWithCode from "../../../modals/ConfirmWithCode/ConfirmWithCode";
import { checkPhoneForResetPassword, registerApi, resetPassword } from "../../../../api/userApi";
import Input from "../../../forms/Input/Input";
import SubmitBtn from "../../../forms/btns/SubmitBtn/SubmitBtn";
import SubmitBtnSecondery from "../../../forms/btns/SubmitBtn/SubmitBtnSecondary";


const HeaderRightNav = () => {
  const { products } = useAppSelector((state) => state.favoritesSlice);
  const {
    authorized,
    visibleSignUpModal,
    visibleLoginModal,
    visibleCheckCodeModal,
    phoneNum,
    enterPass,
    userName,
    visibleModalPhone,
    visibleModalCodeResetPass,
    resetPasswordLoading
  } = useAppSelector((state) => state.userSlice);
  const dispatch = useAppDispatch();
  const [phone, setPhone] = React.useState<string>("");

  return (
    <div className="headerRightNav">
      <nav className="nav">
        <ul className="headerRightNav-menu flex flex-row items-center">
          <li className="headerRightNav-list_item hidden md:block mr-7 md:mr-5 lg:mr-10 h-6 w-6">
            {authorized?
              <NavLink
              title="Избранные"
              to={"/favorites"}
              className={({ isActive }) =>
                isActive ? "active__fav_link" : "fav_link headerRightNav-link"
              }
            >
              <Badge count={products.length} overflowCount={99}>
                <FavoriteIcon />
              </Badge>
            </NavLink>:
            <button title="Избранные" onClick={() => dispatch(setVisibleLoginModalAC(true))} className="headerRightNav-link" >
                <FavoriteIcon />
            </button>
            }
          </li>
          <li className="headerRightNav-list_item headerRightNav-list_item-cart h-6 w-6">
            <CartContianer />
          </li>
          <li className="headerRightNav-list_item hidden md:block ml-11 md:ml-5 lg:ml-11 h-6 w-6">
            {authorized ? (
              <NavLink
                title="Профиль"
                to={"/profile/me"}
                className={({ isActive }) =>
                  isActive ? "active__fav_link" : "fav_link headerRightNav-link"
                }
              >
                <ProfileIcon fill="#070707" />
              </NavLink>
            ) : (
              <button title="Войти" onClick={() => dispatch(setVisibleLoginModalAC(true))} className="headerRightNav-link" >
                <ProfileIcon />
              </button>
            )}
          </li>
        </ul>
      </nav>

      <SignUpModal
        open={visibleSignUpModal}
        setOpen={(v) => dispatch(setVisibleSignUpModalAC(v))}
      />
      <LoginModal
        open={visibleLoginModal}
        setOpen={(v) => dispatch(setVisibleLoginModalAC(v))}
      />

      <Modal
        footer={
          <div className="flex flex-row justify-between my-7">
            <SubmitBtn
              className=""
              onClick={() => {
                dispatch(checkPhoneForResetPassword(phone));
                // dispatch(resetPassword(phone));
                setPhone("");
              }}
              txt="Подтвердить"
            />

            <SubmitBtnSecondery
              className=""
              onClick={() => {
                setPhone("");
                dispatch(setVisibleModalPhoneAC(false))
              }}
              txt="Отмена"
            />
          </div>
        }
        open={visibleModalPhone}
        destroyOnClose
        onCancel={() => dispatch(setVisibleModalPhoneAC(false))}
      >
        <div>
          <h5 className="text-2xl font-medium mt-5" >На номер вышлем пароль</h5>
          <div className="my-2.5">
            <Input
              inpId=""
              inpName=""
              onChange={(v) => {
                setPhone(v);
              }}
              value={phone}
              defaultValue=""
              disabled={false}
              inpClassName=""
              inpType="tel"
              labelTxt="Номер телефона"
            />
          </div>
        </div>
      </Modal>

      <ConfirmWithCode
        loading={resetPasswordLoading}
        onClick={(code) =>{
          dispatch(resetPassword({code,phone}));
        }
        }
        open={visibleModalCodeResetPass}
        setOpen={(v) => dispatch(setVisibleModalCodeResetPassAC(v))}
      />

      {/* <Modal
      footer={
        <div className="flex flex-row justify-between my-7">
          <SubmitBtn
            className=""
            onClick={() => {
              dispatch(resetPassword(""));
              setNewPassword("")
            }}
            txt="Подтвердить"
          />

          <SubmitBtnSecondery
            className=""
            onClick={() => {
              setNewPassword("")
            }}
            txt="Отмена"
          />
        </div>
      }
      destroyOnClose 
      open={visibleModalResetPass}

        onCancel={()=>{
            dispatch(setVisibleModalResetPassAC(false))
        }}
      >
          <div className="my-2.5">
              <Input
                inpId=""
                inpName=""

                onChange={(v) => {setNewPassword(v)}}
                value={newPassword}
                defaultValue=""
                disabled={false}
                inpClassName=""
                inpType="text"
                labelTxt="Новый пароль"
              />
          </div>
      </Modal> */}

      <ConfirmWithCode
        onClick={(code) =>
          dispatch(
            registerApi({
              code,
              phone: phoneNum,
              password: enterPass,
              username: userName,
            })
          )
        }
        open={visibleCheckCodeModal}
        setOpen={(v) => dispatch(setVisibleCheckCodeModalAC(v))}
      />
    </div>
  );
};

export default HeaderRightNav;
