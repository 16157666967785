import React from "react";
import IncDecBtn from "../../forms/btns/IncDecBtn/IncDecBtn";
import { CloseIcon, ImageIcon } from "../../icons";
import { IProductCart, productColorType } from "../../../types/productTypes";
import styles from "./ProductInCart.module.scss";
import { useAppDispatch } from "../../../store/store";
import {
  decremmentProductAC,
  incremmentProductAC,
  removeProductFromCartAC,
} from "../../../store/cartSlice";
import { renderPrice } from "../../../utils/renderPrice";
import returnDiscount from "../../../utils/returnDiscount";
import { TfiClose } from "react-icons/tfi";

const ProductInCart: React.FC<IProductCart> = ({
  actions,
  add_date,
  amount,
  brand,
  category_id,
  color,
  count,
  description,
  finish,
  good_id,
  id,
  images,
  is_avaiable,
  is_hit,
  name,
  price,
  skin,
  volume,
  category_name
}: IProductCart): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <article
      className={`${styles.ProductInCart} border-b border-gray-400 p-2.5 mt-3`}
    >
      <div className="mr-2.5 md:mr-[15px]">
        {images?.length ? (
          <img
            src={images[0]?.image}
            alt={`${images[0]?.id}product${id}`}
            className="productInCart w-20 h-[120px] md:w-[110px] md:h-[165px]"
          />
        ) : (
          <div className="border border-strokeGrey min-w-[80px] min-h-[120px] max-w-[160px] md:max-w-[270px] flex justify-center items-center">
            <ImageIcon className="w-10 h-10" />
          </div>
        )}
      </div>
      <div>
        <div>
          <p className="text-grey text-sm font-normal">{category_name}</p>
          <h5 className="productTitle text-sm md:text-base uppercase text-primary font-semibold max-w-[194px] md:maw-w-[395px]">
            {name}
          </h5>
          <div className="flex flex-row item-center my-2.5">
            <div
              className="product__color w-5 h-5 rounded-full mr-2.5"
              style={{ background: color?.hex_value }}
            ></div>
            <p className="text-grey font-normal text-sm">{color?.name}</p>
          </div>
        </div>
        <div className="flex flex-row md:flex-col justify-between md:justify-start items-center md:items-start" >
        <div className="product__price-container mb-3.5">
          {renderPrice(price, returnDiscount(actions))}
          
        </div>
          <IncDecBtn
            count={count}
            amount={amount}
            onDec={() => {
              dispatch(decremmentProductAC(id));
            }}
            onInc={() => {
              dispatch(incremmentProductAC(id));
            }}
          />
        </div>
        
      </div>
      <div>
        <button
          className="text-gray-400 hover:text-primary"
          onClick={() => dispatch(removeProductFromCartAC(id))}
        >
          <TfiClose className="w-5 h-5" />
        </button>
      </div>
    </article>
  );
};

export default ProductInCart;
