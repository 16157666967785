import { createAsyncThunk } from "@reduxjs/toolkit";
import $authHost from "./authApi";

export const addToFavsApi = createAsyncThunk(
  "favorites/add",
  async (formData: number | string, { rejectWithValue, getState }: any) => {
    try {
      const data: any = await $authHost.post(
        `/favorites/`,
        {
          item: formData,
        }
      );
      return data.data;
    } catch (error: any) {
      if (error?.response?.status === 400) {
        return rejectWithValue("Товар уже есть в избранном!");
      } else if (error?.response?.status === 401) {
        return rejectWithValue("Пользователь не авторизован!");
      } else if (error?.response?.status === 404) {
        return rejectWithValue("Продукт не найден!");
      } else {
        return rejectWithValue("Авторизуйтесь чтоб поставить в избранное!");
      }
    }
  }
);
export const deleteToFavsApi = createAsyncThunk(
  "favorites/delete",
  async (formData: number | string|undefined, { rejectWithValue, getState }: any) => {
    try {
      const data: any = await $authHost.delete(
        `/favorites/${formData}/`
      );
      return formData;
    } catch (error: any) {
      if (error?.response?.status === 400) {
        return rejectWithValue("Товара нету в избранном!");
      } else if (error?.response?.status === 401) {
        return rejectWithValue("Пользователь не авторизован!");
      } else if (error?.response?.status === 404) {
        return rejectWithValue("Продукт не найден!");
      } else {
        return rejectWithValue("Не известная ошибка!");
      }
    }
  }
);
export const getToFavsApi = createAsyncThunk(
  "favorites/get",
  async (_, { rejectWithValue, getState }: any) => {
    try {
      const data: any = await $authHost.get(
        `/favorites/`
      );
      return data.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return rejectWithValue("Пользователь не авторизован!");
      } else if (error?.response?.status === 404) {
        return rejectWithValue("Продукт не найден!");
      } else {
        return rejectWithValue("Не известная ошибка!");
      }
    }
  }
);
