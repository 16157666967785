import React from "react";
import logo1 from "../imgs/смит 1.png";
import { Link } from "react-router-dom";

const PageNotFound: React.FC = (): JSX.Element => {
  return (
    <div>
      <div className="flex items-center flex-col py-5 md:py-20 lg:py-32">
        <h2 className="text-7xl font-bold text-primary mb-3 tracking-wider">
          404
        </h2>
        <div className="logo--container my-2.5 md:my-6 flex justify-center">
          <img src={logo1} alt="logo1" className="max-w-xs logo-big" />
        </div>
        <div>
          <h5 className="mt-5 text-xl font-semibold tracking-wide">
            Страница не найдена!
          </h5>
        </div>
        <Link
          to="/"
          className="bg-secondary text-white border-secondary border hover:bg-white hover:text-secondary font-medium text-base py-1.5 px-3 mt-5 duration-300"
        >
          НА ГЛАВНУЮ
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
