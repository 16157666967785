import React from "react";
import { IconProps } from "../../types/appTypes";

const SearcherIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "25"}
      height={height ? height : "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.5 22.2323L17.7419 17.6015M17.7419 17.6015C19.4164 15.9719 20.357 13.7617 20.357 11.4571C20.357 9.15251 19.4164 6.94229 17.7419 5.31268C16.0675 3.68308 13.7965 2.76758 11.4285 2.76758C9.06053 2.76758 6.78952 3.68308 5.1151 5.31268C3.44068 6.94229 2.5 9.15251 2.5 11.4571C2.5 13.7617 3.44068 15.9719 5.1151 17.6015C6.78952 19.2312 9.06053 20.1467 11.4285 20.1467C13.7965 20.1467 16.0675 19.2312 17.7419 17.6015Z"
        stroke={color ? color : "#070707"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearcherIcon;
