import React from "react";

import { Badge, Drawer, Empty, Modal } from "antd";
import ProductInCart from "../../ui/ProductCard/ProductInCart";
import { TfiClose } from "react-icons/tfi";

import { CartIcon, EmptyBoxIcon } from "../../icons";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { clearProductsCartAC, setOpenCartAC } from "../../../store/cartSlice";
import { IProductCart } from "../../../types/productTypes";

const CartContianer = () => {
  // const [childrenDrawer, setChildrenDrawer] = React.useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { products, totalCountProducts, totalPrice, open } = useAppSelector(
    (state) => state.cartSlice
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(clearProductsCartAC());
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const showChildrenDrawer = () => {
  //   setChildrenDrawer(true);
  // };

  // const onChildrenDrawerClose = () => {
  //   setChildrenDrawer(false);
  // };

  if (window.outerWidth > 768) {
    return (
      <div>
        <Drawer
          title={
            <div>
              <h2 className="text-3xl">Корзина</h2>
              <p className="text-gray-400 text-base font-light">
                Выбрано: {totalCountProducts} товар(а/ов)
              </p>
            </div>
          }
          placement={"right"}
          onClose={() => dispatch(setOpenCartAC(false))}
          open={open}
          closeIcon={<TfiClose className="w-7 h-7 text-primary hover:text-secondary" />}
          destroyOnClose={true}
          closable={true}
          width={610}
          footer={
            <div className="flex flex-row items-center justify-between py-5">
              <div>
                <h6 className="text-sm font-light text-grey">Итого:</h6>
                <h5 className="price font-semibold text-[28px] leading-[34px] mt-1">
                  {(Math.round(totalPrice * 100) / 100).toLocaleString('ru-RU')} с.
                </h5>
              </div>
              <div>
                {totalCountProducts ? (
                  <NavLink
                    to="/order/"
                    onClick={() => dispatch(setOpenCartAC(false))}
                    // onClick={showModal}
                    className="hover:bg-secondary hover:text-white duration-300 bg-primary text-white py-4 px-12 text-base font-medium"
                  >
                    Оформить заказ
                  </NavLink>
                ) : (
                  <button
                    disabled
                    className="hover:bg-secondary hover:text-white duration-300 bg-primary disabled:bg-strokeGrey text-white py-4 px-12 text-base font-medium"
                  >
                    Оформить заказ
                  </button>
                )}
              </div>
            </div>
          }
        >
          <Modal
            closeIcon={<TfiClose className="w-7 h-7 text-primary hover:text-secondary" />}
            style={{ top: "70vh" }}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
              <div className="flex flex-row justify-between items-end gap-5">
                <button
                  onClick={handleCancel}
                  className="hover:bg-secondary hover:text-white duration-300 bg-strokeGrey text-primary py-4 px-7 text-base font-medium w-1/2"
                >
                  Нет, оставить
                </button>
                <button
                  onClick={handleOk}
                  className="hover:bg-secondary duration-300 bg-primary text-white py-4 px-7 text-base font-medium w-1/2"
                >
                  Удалить
                </button>
              </div>
            }
          >
            <div>
              <div>
                <h6 className="text-primary font-semibold text-xl md:text-[32px] md:leading-10">
                  Очистить корзину?
                </h6>
                <p className="text-primary font-normal mt-2.5 mb-10 text-sm">
                  Вы уверены, что хотите удалить все товары с корзины?
                </p>
              </div>
            </div>
          </Modal>

          {products.length ? (
            <div className="cart__products--container">
              <div className="cart__products--list">
                {products.map((item: IProductCart) => (
                  <ProductInCart key={item.id} {...item} />
                ))}
                <div className="flex justify-end mt-5">
                  <button
                    onClick={showModal}
                    className="font-normal text-xs text-[#808080] underline"
                  >
                    Очистить корзину
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="my-20">
              <Empty
                description={
                  <p className="text-strokeGrey text-2xl mt-2 leading-[39px] font-semibold">
                    Пока тут пусто...
                  </p>
                }
                image={<EmptyBoxIcon />}
              />
            </div>
          )}
        </Drawer>
        <button title="Корзина" onClick={() => dispatch(setOpenCartAC(true))}>
          <Badge count={totalCountProducts} overflowCount={99}>
            <CartIcon color="#070707" />
          </Badge>
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <Drawer
          title={
            <div>
              <h2 className="text-xl">Корзина</h2>
              <p className="text-gray-400 text-xs font-light">
                Выбрано: {totalCountProducts} товар(а/ов)
              </p>
            </div>
          }
          placement={"bottom"}
          onClose={() => dispatch(setOpenCartAC(false))}
          open={open}
          closeIcon={<TfiClose className="w-7 h-7 text-primary hover:text-secondary" />}
          destroyOnClose={true}
          closable={true}
          height={"90%"}
          footer={
            <div className="flex flex-row items-center justify-between text-base py-5">
              <div>
                <h6 className="text-xs md:text-sm font-light text-grey">
                  Итого:
                </h6>
                <h5 className="price font-semibold text-2xl leading-7 md:text-[28px] md:leading-[34px] mt-1">
                  {(Math.ceil(totalPrice * 100) / 100).toLocaleString('ru-RU')} с.
                </h5>
              </div>
              <div>
                {totalCountProducts ? (
                  <NavLink
                    to="/order/"
                    onClick={() => dispatch(setOpenCartAC(false))}
                    className="hover:bg-secondary duration-300 bg-primary text-white py-4 px-7 text-base font-medium"
                  >
                    Оформить заказ
                  </NavLink>
                ) : (
                  <button
                    disabled
                    className="hover:bg-secondary duration-300 bg-primary disabled:bg-strokeGrey text-white py-4 px-7 text-base font-medium"
                  >
                    Оформить заказ
                  </button>
                )}
              </div>
            </div>
          }
        >
          <Modal
            style={{ top: "70vh" }}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
              <div className="flex flex-row justify-between items-end gap-2">
                <button
                  onClick={handleCancel}
                  className="hover:bg-secondary hover:text-white duration-300 bg-strokeGrey text-primary py-4 px-7 text-base font-medium w-1/2"
                >
                  Нет, оставить
                </button>
                <button
                  onClick={handleOk}
                  className="hover:bg-secondary duration-300 bg-primary text-white py-4 px-7 text-base font-medium w-1/2"
                >
                  Удалить
                </button>
              </div>
            }
          >
            <div>
              <div>
                <h6 className="text-primary font-semibold text-xl md:text-[32px] md:leading-10">
                  Очистить корзину?
                </h6>
                <p className="text-primary font-normal mt-2.5 mb-10 text-sm">
                  Вы уверены, что хотите удалить все товары с корзины?
                </p>
              </div>
            </div>
          </Modal>

          {products.length ? (
            <div className="cart__products--container">
              <div className="cart__products--list">
                {products.map((item: IProductCart) => (
                  <ProductInCart key={item.id} {...item} />
                ))}
                <div className="flex justify-end mt-5">
                  <button
                    onClick={showModal}
                    className="text-[#808080] font-normal text-base underline"
                  >
                    Очистить корзину
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="my-20">
              <Empty
                description={
                  <p className="text-strokeGrey text-2xl mt-2 leading-[39px] font-semibold">
                    Пока тут пусто...
                  </p>
                }
                image={<EmptyBoxIcon />}
              />
            </div>
          )}
        </Drawer>
        <button title="Корзина" onClick={() => dispatch(setOpenCartAC(true))}>
          <Badge count={totalCountProducts} overflowCount={99}>
            <CartIcon color="#070707" />
          </Badge>
        </button>
      </div>
    );
  }
};

export default CartContianer;
