import { createAsyncThunk } from "@reduxjs/toolkit";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import $authHost from "./authApi";
import axios from "axios";

interface getAllProductsParamsType {
  page?: string | number;
  limit?: string | number;
  good_id?: string | number;
  is_hit?: string | number;
  is_avaiable?: string | number;
  add_date?: string;
  brand_id?: CheckboxValueType[] | any[] | string;
  action_id?: string | number | null;
  category_id?: string | number | null;
  price_min?: string | number;
  price_max?: string | number;
  skin?: CheckboxValueType[] | any[] | string | number;
  finish?: string | number;
  name?: CheckboxValueType[] | any[] | string;
  color?: string;
  volume?: string;

  addMore?: boolean;
  is_in_action?: number;
  search?: string | null | undefined;
}

export const getAllProductsAPI = async (params:any) => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_API_URL
    }/items?page=${1}&limit=${8}`,
    { params: params }
  );
  if (response.status !== 200) {
    throw new Error("Network response was not ok");
  }
  return response.data;
}

export const getAllProducts = createAsyncThunk(
  "productsSlice/items/all",
  async (
    {
      page = 1,
      limit = 10,
      good_id,
      is_hit,
      is_avaiable,
      add_date,
      brand_id,
      action_id,
      category_id,
      price_min,
      price_max,
      skin,
      finish,
      name,
      color,
      volume,

      addMore,
      is_in_action,
      search
    }: getAllProductsParamsType,
    { getState, rejectWithValue }
  ) => {
    try {
      const data: any = await $authHost.get(
        `/items?page=${page}&limit=${limit}&is_avaiable=${1}`,
        {
          params: {
            good_id,
            is_hit,
            add_date,
            // @ts-ignore
            brand_id: brand_id ? brand_id?.join(",") : undefined,
            action_id,
            category_id,
            price_min,
            price_max,
            color,
            volume,
            // @ts-ignore
            skin: skin?.length ? skin?.join(",") : undefined,
            // @ts-ignore
            finish: finish?.length ? finish?.join(",") : undefined,
            is_in_action,
            search
          },
        }
      );
      // const data: any = await axios.get(
      //   `${
      //     process.env.REACT_APP_API_URL
      //   }/items/?page=${1}&limit=${20}&is_avaiable=1`
      // );
      return { ...data.data, addMore };
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);

export const getAllProductsSearch = createAsyncThunk(
  "productsSlice/items/search",
  async (
    {
      page = 1,
      limit = 5,
      good_id,
      is_hit,
      is_avaiable,
      add_date,
      brand_id,
      action_id,
      category_id,
      price_min,
      price_max,
      skin,
      finish,
      name,
      color,
      volume,
      addMore,
      is_in_action,
      search
    }: getAllProductsParamsType,
    { getState, rejectWithValue }
  ) => {
    try {
      console.log("brand_id", brand_id);
      const data: any = await $authHost.get(
        `/items/?page=${page}&limit=${limit}&is_avaiable=1`,
        {
          params: {
            is_hit,
            add_date,
            // @ts-ignore
            brand_id: brand_id ? brand_id?.join(",") : undefined,
            action_id,
            category_id,
            price_min,
            price_max,
            
            color,
            volume,
            // @ts-ignore
            skin: skin?.length ? skin?.join(",") : undefined,
            // @ts-ignore
            finish: finish?.length ? finish?.join(",") : undefined,
            is_in_action,
            search
          },
        }
      );
      return { ...data.data, addMore };
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);

export const getOneProductThunk = createAsyncThunk(
  "productsSlice/item/byId",
  async ({ item_id }: any, { getState, rejectWithValue }) => {
    try {
      const data: any = await $authHost.get(`/items/${item_id}`);

      const products: any = await $authHost.get(
        `/items/?is_avaiable=1&good_id=${data.data?.good_id}`
      );

      return { product: data.data, products: products.data };
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);
