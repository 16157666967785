import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ProductInFav } from "../types/productTypes";
import { addToFavsApi, deleteToFavsApi, getToFavsApi } from "../api/favoritesApi";
import { notification } from "antd";

export interface favoritesState {
  products: ProductInFav[];
  addingToFavs: boolean;
  errAddToFav: string;
}

const initialState: favoritesState = {
  products: [],
  addingToFavs: false,
  errAddToFav: ""
};

export const favoritesSlice = createSlice({
  name: "favoritesSlice",
  initialState,
  reducers: {
    incremmentProductAC(state, action: PayloadAction<number|string>) {
      state.products.map((e) => {
        if (e.id === action.payload) {
          return e.count++;
        } else {
          return e;
        }
      });
      //@ts-ignore
      state.totalCountProducts = state.products.reduce((a, b) => {
        return a + b.count;
      }, 0);
      //@ts-ignore
      state.totalPrice = state.products.reduce((a, b) => {
        return a + b.item_in_favorite.price * b.count;
      }, 0);
    },
    decremmentProductAC(state, action: PayloadAction<number|string>) {
      state.products.map((e) => {
        if (e.id === action.payload) {
          return e.count--;
        } else {
          return e;
        }
      });
      //@ts-ignore
      state.totalCountProducts = state.products.reduce((a, b) => {
        return a + b.count;
      }, 0);
      //@ts-ignore
      state.totalPrice = state.products.reduce((a, b) => {
        return a + b.item_in_favorite.price * b.count;
      }, 0);
    },
    clearFavsAC(state){
      state.products=[];
    }
  },
  extraReducers(builder) {
    builder.addCase(addToFavsApi.pending, (state) => {
      state.errAddToFav = "";
      state.addingToFavs = true;
    });
    builder.addCase(
      addToFavsApi.rejected,
      (state, action: PayloadAction<any>) => {
        state.errAddToFav = action.payload;
        state.addingToFavs = false;
        notification.error({message:action.payload});
      }
    );
    builder.addCase(addToFavsApi.fulfilled, (state, action) => {
      state.errAddToFav = "";
      state.addingToFavs = false;
      // if (state.products.length < 1) {
        state.products.push({...action.payload, count: 1});
      // } else if (state.products.length > 0) {
      //   for (let i = 0; i < state.products.length; i++) {
      //     if (state.products[i].id !== action.payload.id && action.payload.id) {
      //       state.products.push(action.payload);
      //       break;
      //     }
      //   }
      // }
    });
    builder.addCase(deleteToFavsApi.pending, (state) => {
      state.errAddToFav = "";
      state.addingToFavs = true;
    });
    builder.addCase(
      deleteToFavsApi.rejected,
      (state, action: PayloadAction<any>) => {
        state.errAddToFav = action.payload;
        state.addingToFavs = false;
        notification.error({message:action.payload});
      }
    );
    builder.addCase(deleteToFavsApi.fulfilled, (state, action) => {
      state.errAddToFav = "";
      state.addingToFavs = false;
      state.products = state.products.filter((e) => e.id !== action.payload);
    });
    builder.addCase(getToFavsApi.pending, (state) => {
      state.errAddToFav = "";
      state.addingToFavs = true;
    });
    builder.addCase(
      getToFavsApi.rejected,
      (state, action: PayloadAction<any>) => {
        state.errAddToFav = action.payload;
        state.addingToFavs = false;
        state.products=[];
      }
      );
      builder.addCase(getToFavsApi.fulfilled, (state, action) => {
        state.errAddToFav = "";
        state.addingToFavs = false;
        state.products=action.payload.map((elem:any)=>({...elem, count:1}));
    });

  },
});

export const {
  incremmentProductAC,
  decremmentProductAC,
  clearFavsAC
} = favoritesSlice.actions;

export default favoritesSlice.reducer;
