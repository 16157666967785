import React from "react";
import { IconProps } from "../../types/appTypes";

const Logo: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "41"}
      height={height ? height : "40"}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3181 24.1036L35.316 24.0999V24.1114L35.3181 24.1036ZM35.3181 24.1036L37.6889 28.2094C39.0135 25.3833 39.6363 22.2792 39.5043 19.1609C39.3724 16.0425 38.4897 13.0021 36.9311 10.298C35.3725 7.59384 33.1842 5.3059 30.5521 3.62857C27.92 1.95125 24.9218 0.934104 21.8124 0.663574L24.1796 4.77883C26.1848 5.30922 28.0652 6.23144 29.7122 7.49229C31.3591 8.75314 32.7401 10.3276 33.7755 12.125C34.8108 13.9223 35.48 15.9068 35.7444 17.9641C36.0085 20.0187 35.8636 22.1053 35.3181 24.1036Z"
        fill={color ? color : "black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.762 19.7154C28.5025 19.8439 29.1642 20.2549 29.6076 20.8617C30.025 21.4463 30.244 22.1494 30.2323 22.8677C30.2683 23.433 30.1691 23.9988 29.9428 24.5181C29.7165 25.0374 29.3698 25.4953 28.9313 25.8539C27.8524 26.5934 26.557 26.9505 25.2516 26.8684H18.4884V25.2177L19.9557 24.9311V21.1597H14.3617V24.9311L15.8289 25.2177V26.8684H10.149V25.2177L11.6105 24.9311V15.0785L10.149 14.792V13.1355H15.8347V14.8092L14.3617 15.0957V18.9932H19.9499V15.0327L18.4826 14.7461V13.0897H24.5982C25.9215 13.0143 27.2365 13.3421 28.3696 14.0297C28.8179 14.3447 29.178 14.7692 29.4158 15.2628C29.6536 15.7564 29.7611 16.3027 29.728 16.8496C29.7417 17.4611 29.5655 18.0619 29.2236 18.5691C28.8675 19.0939 28.3566 19.4946 27.762 19.7154ZM26.3807 15.709C25.8509 15.3698 25.2257 15.211 24.5982 15.2562H22.7125V18.89H24.6613C25.2685 18.9314 25.8726 18.7723 26.3807 18.4372C26.5755 18.2787 26.7293 18.0757 26.829 17.8452C26.9288 17.6148 26.9716 17.3637 26.9539 17.1132C26.9771 16.8505 26.9372 16.5859 26.8375 16.3417C26.7378 16.0974 26.5812 15.8805 26.3807 15.709ZM25.2459 24.742C25.8405 24.7841 26.4309 24.6157 26.9138 24.2662C27.1095 24.0922 27.2629 23.8757 27.3622 23.6333C27.4616 23.391 27.5042 23.1291 27.4869 22.8677C27.5232 22.3109 27.3493 21.7608 26.9997 21.3259C26.7936 21.1336 26.5492 20.9868 26.2826 20.8949C26.016 20.8031 25.7331 20.7683 25.4522 20.7929H22.7125V24.742H25.2459Z"
        fill={color ? color : "black"}
      />
      <path
        d="M36.0959 30.9721C34.3211 33.5515 31.9457 35.6607 29.1744 37.1179C26.4031 38.575 23.319 39.3364 20.1879 39.3364C17.0569 39.3364 13.9728 38.575 11.2015 37.1179C8.43016 35.6607 6.05475 33.5515 4.27998 30.9721H9.00278C10.4601 32.4583 12.1991 33.6389 14.1182 34.4449C16.0373 35.2509 18.0979 35.666 20.1793 35.666C22.2608 35.666 24.3214 35.2509 26.2404 34.4449C28.1595 33.6389 29.8986 32.4583 31.3559 30.9721H36.0959Z"
        fill={color ? color : "black"}
      />
      <path
        d="M4.63465 17.9561C4.36941 20.0121 4.51369 22.1002 5.05918 24.1002L2.68631 28.2211C1.36172 25.395 0.73892 22.2909 0.870847 19.1726C1.00277 16.0542 1.88552 13.0138 3.44409 10.3097C5.00265 7.60553 7.19097 5.3176 9.82309 3.64027C12.4552 1.96294 15.4533 0.945798 18.5627 0.675268L16.1956 4.77906C14.1916 5.30953 12.3124 6.23137 10.6663 7.49144C9.02025 8.75151 7.63982 10.3249 6.60462 12.1209C5.56941 13.917 4.89989 15.9001 4.63465 17.9561Z"
        fill={color ? color : "black"}
      />
    </svg>
  );
};

export default Logo;
