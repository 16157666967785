import React from "react";
import { IconProps } from "../../types/appTypes";

const ArrowLeftIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "37"}
      height={height ? height : "23"}
      viewBox="0 0 37 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.5479 1.41455L1.54785 11.4146M1.54785 11.4146L11.5479 21.4146M1.54785 11.4146C1.54785 11.4146 26.5658 11.4146 35.5479 11.4146"
        stroke={color ? color : "#070707"}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
