import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { categoryType } from "../types/productTypes";
import { getAllCategories } from "../api/appApi";
import { createNestedStructure } from "../utils/filterCatalog";

export interface BrandState {
  isFetchingCategories: boolean;
  isErrCategories: string;
  categories: categoryType[] | null;
}

const initialState: BrandState = {
  categories: null,
  isErrCategories: "",
  isFetchingCategories: false,
};

export const categorySlice = createSlice({
  name: "categorySlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllCategories.pending, (state) => {
      state.isErrCategories = "";
      state.isFetchingCategories = true;
    });
    builder.addCase(
      getAllCategories.rejected,
      (state, action: PayloadAction<any>) => {
        state.isErrCategories = action.payload;
        state.isFetchingCategories = false;
        state.categories = null;
      }
    );
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.isErrCategories = "";
      state.isFetchingCategories = false;

      state.categories = createNestedStructure(action.payload);
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = categorySlice.actions;

export default categorySlice.reducer;
