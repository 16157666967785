import React from "react";
import { IconProps } from "../../../types/appTypes";

const LocationIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "31"}
      height={height ? height : "31"}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 30.9658C23.7843 30.9658 30.5 24.2501 30.5 15.9658C30.5 7.68155 23.7843 0.96582 15.5 0.96582C7.21573 0.96582 0.5 7.68155 0.5 15.9658C0.5 24.2501 7.21573 30.9658 15.5 30.9658ZM15.1809 24.1532L15.1236 24.1205C14.8034 23.9309 14.4908 23.7286 14.1868 23.514C13.4008 22.9593 12.6663 22.3351 11.9925 21.6487C10.4027 20.0222 8.75 17.5774 8.75 14.4338C8.75 12.6453 9.46116 10.9301 10.727 9.66551C11.9929 8.40089 13.7098 7.69043 15.5 7.69043C17.2902 7.69043 19.0071 8.40089 20.273 9.66551C21.5388 10.9301 22.25 12.6453 22.25 14.4338C22.25 17.5782 20.5981 20.0222 19.0075 21.6487C18.3334 22.3351 17.5987 22.9594 16.8124 23.514C16.5086 23.7286 16.1964 23.9309 15.8764 24.1205L15.8183 24.154L15.7954 24.1662C15.7045 24.2153 15.6029 24.241 15.4996 24.241C15.3963 24.241 15.2947 24.2153 15.2038 24.1662L15.1809 24.1532ZM17.2356 16.1677C16.7753 16.6275 16.151 16.8859 15.5 16.8859C14.849 16.8859 14.2247 16.6275 13.7644 16.1677C13.3041 15.7078 13.0455 15.0841 13.0455 14.4338C13.0455 13.7834 13.3041 13.1597 13.7644 12.6999C14.2247 12.24 14.849 11.9816 15.5 11.9816C16.151 11.9816 16.7753 12.24 17.2356 12.6999C17.6959 13.1597 17.9545 13.7834 17.9545 14.4338C17.9545 15.0841 17.6959 15.7078 17.2356 16.1677Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export default LocationIcon;
