import React from "react";
import { IconProps } from "../../../types/appTypes";

const EmailIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width?width:"31"}
      height={height?height:"31"}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 15.9658C30.5 24.2501 23.7843 30.9658 15.5 30.9658C7.21573 30.9658 0.5 24.2501 0.5 15.9658C0.5 7.68155 7.21573 0.96582 15.5 0.96582C23.7843 0.96582 30.5 7.68155 30.5 15.9658ZM8 13.5894V19.7119C8 20.2796 8.22576 20.8241 8.62763 21.2256C9.02949 21.6271 9.57454 21.8526 10.1429 21.8526H20.8571C21.4255 21.8526 21.9705 21.6271 22.3724 21.2256C22.7742 20.8241 23 20.2796 23 19.7119V13.5894L16.6229 17.5091C16.2852 17.7166 15.8965 17.8265 15.5 17.8265C15.1035 17.8265 14.7148 17.7166 14.3771 17.5091L8 13.5894ZM23 12.2194V12.3321L16.0614 16.5979C15.8926 16.7017 15.6982 16.7566 15.5 16.7566C15.3018 16.7566 15.1074 16.7017 14.9386 16.5979L8 12.3321V12.2194C8 11.6516 8.22576 11.1071 8.62763 10.7056C9.02949 10.3042 9.57454 10.0786 10.1429 10.0786H20.8571C21.4255 10.0786 21.9705 10.3042 22.3724 10.7056C22.7742 11.1071 23 11.6516 23 12.2194Z"
        fill={color?color:"white"}
      />
    </svg>
  );
};

export default EmailIcon;
