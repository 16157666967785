import { FieldErrorsImpl } from "react-hook-form";

export const errorDetail = (
  name: string,
  errors?: Partial<FieldErrorsImpl<any>>
): string => {
  if (errors) {
    if (errors[name]) {
      return `<div className="ant-form-explain">${errors[name]?.message}</div>`;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
