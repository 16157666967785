export const renderPrice = (price: number, discount?: number) => {
  if (discount) {
    const discountedPrice = price - (price * discount) / 100;
    return (
      <>
        <span className="product-price product-price-discounted text-base md:text-lg font-medium md:font-semibold text-black">
          {(Math.round(discountedPrice * 100) / 100).toLocaleString('ru-RU')} c.
        </span>
        <span className="product-price product-price-original line-through text-base font-medium text-[#767676] ml-3">
          {(Math.round(price * 100) / 100).toLocaleString('ru-RU')} c.
        </span>
      </>
    );
  }

  return (
    <span className="product-price text-base md:text-lg font-medium md:font-semibold text-black">
      {(Math.round(price * 100) / 100).toLocaleString('ru-RU')} c.
    </span>
  );
};
