import { Modal } from "antd";
import React from "react";
import Input from "../../forms/Input/Input";
import SubmitBtn from "../../forms/btns/SubmitBtn/SubmitBtn";
import { Link } from "react-router-dom";
import { ModalsType } from "../../../types/appTypes";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  changeEnterPassAC,
  changePhonNumAC,
  setVisibleLoginModalAC,
  setVisibleModalPhoneAC,
  setVisibleSignUpModalAC,
} from "../../../store/userSlice";
import { loginApi } from "../../../api/userApi";
import { TfiClose } from "react-icons/tfi";

const LoginModal: React.FC<ModalsType> = ({ open, setOpen }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { phoneNum, enterPass, fetchingUserData } = useAppSelector((state) => state.userSlice);
  const [visiblePass,setVisiblePass] = React.useState<boolean>(false);

  return (
    <Modal
      footer={null}
      open={open}
      onCancel={() => setOpen(false)}
      destroyOnClose={true}
      closeIcon={<TfiClose className="w-5 h-5" />}
    >
      <div className="pt-[50px] py-10 pb-10">
        <h4 className="mb-5 text-xl font-semibold md:text-[32px] text-primary">
          Войти
        </h4>
        <div>
          <div className="my-2.5">
            <Input
              inpId=""
              inpName=""
              onChange={(v) => dispatch(changePhonNumAC(v))}
              value={phoneNum}
              defaultValue=""
              disabled={false}
              inpClassName=""
              inpType="tel"
              labelTxt="Номер телефона"
            />
          </div>
          <div className="my-2.5">
            <Input
              inpId=""
              inpName=""
              onChange={(v) => dispatch(changeEnterPassAC(v))}
              value={enterPass}
              defaultValue=""
              disabled={false}
              inpClassName=""
              inpType="password"
              labelTxt="Пароль"
            />
            <button className="underline text-xs text-lightGrey"
            onClick={()=>{dispatch(setVisibleModalPhoneAC(true));setOpen(false)}}
            >забыл пароль?</button>
          </div>
          <div className="mt-10 mb-[30px]">
            <SubmitBtn
              className=""
              onClick={() =>
                dispatch(loginApi({ phone: phoneNum, password: enterPass }))
              }
              txt="Войти"
              disabled={phoneNum&&enterPass?false:true}
              loading={fetchingUserData}
            />
          </div>
        </div>
        <div>
          <div className="text-sm mb-5">
            <button
              className="text-secondary underline"
              onClick={() => {
                dispatch(setVisibleLoginModalAC(false));
                dispatch(setVisibleSignUpModalAC(true));
              }}
            >
              Зарегистрируйтесь,
            </button>
            чтобы получать дополнительные скидки, добавлять любимые товары в
            избранное и быть в курсе самых последних бьюти-новостей.
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
