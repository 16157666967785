import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { chedckphoneType, giftCardOrderType } from "../types/appTypes";
import { notification } from "antd";

export const getAllCategories = createAsyncThunk(
  "categorySlice/categories",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories/`
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);

export const getMainSlider = createAsyncThunk(
  "app/mainSlider",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/mainpage/`
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);

export const getImgGiftCards = createAsyncThunk(
  "app/getImgGiftCards",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/imagecards/`
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);

export const orderImgGiftCards = createAsyncThunk(
  "app/orderImgGiftCards",
  async (
    {
      address,
      amount,
      customer,
      image,
      nominal,
      phone,
      code,
    }: giftCardOrderType,
    { rejectWithValue }
  ) => {
    // try {

    return await axios
      .post(`${process.env.REACT_APP_API_URL}/checkcode/`, {
        code,
        phone,
      })
      .then(async (response) => {
        if (response.data?.is_valid_code) {
          const ordersData: any = await axios
            .post(`${process.env.REACT_APP_API_URL}/giftcards/`, {
              customer,
              phone,
              address,
              nominal,
              amount,
              image,
            })
            .then((response) => {
              return response.data;
            })
            .catch((err) => {
              return rejectWithValue("giftCards err");
            });
          return response.data;
        } else {
          return rejectWithValue("giftCards err");
        }
      })
      .catch((err) => {
        return rejectWithValue("giftCards err");
      });
  }
);

export const checkPhone = createAsyncThunk(
  "app/checkPhone",
  async ({ phone, is_send_sms }: chedckphoneType, { rejectWithValue }) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/checkphone/`,
        {
          phone: `+992${phone}`,
          is_send_sms,
        }
      );
      if (data.data.is_black_list) {
        notification.error({ message: "Номер находится в черном списке" });
        return rejectWithValue("Номер находится в черном списке");
      } else {
        return {data:data.data, is_send_sms};
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        return rejectWithValue(
          error?.response?.data?.phone?.find((e: string) => e)
        );
      } else {
        return rejectWithValue("check-code err");
      }
    }
  }
);

// interface checkPhoneGiftCardType extends giftCardOrderType  {
  
// }
// chedckphoneType

export const checkPhoneGiftCard = createAsyncThunk(
  "app/checkPhoneGiftCard",
  async ({ phone, is_send_sms, customer,
    address,
    nominal,
    amount,
    image, }: any, { rejectWithValue }) => {
    // try {
      return await axios.post(
        `${process.env.REACT_APP_API_URL}/checkphone/`,
        {
          phone: `+992${phone}`,
          is_send_sms,
        }
      ).then(async(res)=>{

      
      if (res.data.is_black_list) {
        notification.error({ message: "Номер находится в черном списке" });
        return rejectWithValue("Номер находится в черном списке");
      } else {

        return await axios
            .post(`${process.env.REACT_APP_API_URL}/giftcards/`, {
              customer,
              phone:res.data.id,
              address,
              nominal,
              amount,
              image,
            })
            .then((response) => {
              return response.data;
            })
            .catch((err) => {
              notification.error({message: "Ошибка подарочных карт"})
              return rejectWithValue("giftCards err");
            });

        // return {data:res.data, is_send_sms};
      }
    // } 
    })
    .catch ((error: any)=> {
      if (error?.response?.status === 400) {
        return rejectWithValue(
          error?.response?.data?.phone?.find((e: string) => e)
        );
      } else {
        return rejectWithValue("check-code err");
      }
    })
  }
);
export const checkCode = createAsyncThunk(
  "app/checkCode",
  async ({ code, phone }: any, { rejectWithValue }) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/checkcode/`,
        {
          code,
          phone,
        }
      );
      if (data.data.is_valid_code) {
        return data.data;
      } else {
        notification.error({ message: "Не валидный код!" });
        throw new Error("invalid code");
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        return rejectWithValue(
          error?.response?.data?.phone?.find((e: string) => e)
        );
      } else {
        return rejectWithValue("check-code err");
      }
    }
  }
);

export const getAllSkinsType = createAsyncThunk(
  "app/getSkinsType",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/skins/`
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);

export const getAllFinishesType = createAsyncThunk(
  "app/getFinishesType",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/finishes/`
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("error occured");
    }
  }
);
