function returnDiscount(actions: any): any {
  const currentDate = new Date();

  let currentDiscount = null;
  let highestDiscount = 0;

  for (const action of actions) {
    const validFrom = new Date(action.valid_from);
    const validTill = new Date(action.valid_till);

    if (currentDate >= validFrom && currentDate <= validTill) {
      if (action.discount > highestDiscount) {
        currentDiscount = action;
        return (highestDiscount = action.discount);
      }
    }
  }
}

export default returnDiscount;