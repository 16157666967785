import React from "react";
import { IconProps } from "../../types/appTypes";

const LogoWithTxt: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width?width:"182"}
      height={height?height:"40"}
      viewBox="0 0 182 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5984 24.1036L34.5963 24.0999V24.1114L34.5984 24.1036ZM34.5984 24.1036L36.9691 28.2094C38.2937 25.3833 38.9165 22.2792 38.7846 19.1609C38.6527 16.0425 37.7699 13.0021 36.2114 10.298C34.6528 7.59384 32.4645 5.3059 29.8324 3.62857C27.2002 1.95125 24.2021 0.934104 21.0927 0.663574L23.4598 4.77883C25.4651 5.30922 27.3454 6.23144 28.9924 7.49229C30.6394 8.75314 32.0204 10.3276 33.0558 12.125C34.0911 13.9223 34.7603 15.9068 35.0247 17.9641C35.2887 20.0187 35.1439 22.1053 34.5984 24.1036Z"
        fill={color?color:"black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0423 19.7154C27.7828 19.8439 28.4445 20.2549 28.8879 20.8617C29.3053 21.4463 29.5243 22.1494 29.5126 22.8677C29.5486 23.433 29.4493 23.9988 29.2231 24.5181C28.9968 25.0374 28.6501 25.4953 28.2116 25.8539C27.1327 26.5934 25.8373 26.9505 24.5319 26.8684H17.7687V25.2177L19.2359 24.9311V21.1597H13.6419V24.9311L15.1092 25.2177V26.8684H9.42923V25.2177L10.8908 24.9311V15.0785L9.42923 14.792V13.1355H15.1149V14.8092L13.6419 15.0957V18.9932H19.2302V15.0327L17.7629 14.7461V13.0897H23.8785C25.2018 13.0143 26.5168 13.3421 27.6499 14.0297C28.0981 14.3447 28.4583 14.7692 28.6961 15.2628C28.9339 15.7564 29.0413 16.3027 29.0082 16.8496C29.022 17.4611 28.8458 18.0619 28.5039 18.5691C28.1478 19.0939 27.6369 19.4946 27.0423 19.7154ZM25.661 15.709C25.1312 15.3698 24.5059 15.211 23.8785 15.2562H21.9928V18.89H23.9415C24.5488 18.9314 25.1529 18.7723 25.661 18.4372C25.8558 18.2787 26.0095 18.0757 26.1093 17.8452C26.209 17.6148 26.2519 17.3637 26.2342 17.1132C26.2574 16.8505 26.2174 16.5859 26.1178 16.3417C26.0181 16.0974 25.8615 15.8805 25.661 15.709ZM24.5262 24.742C25.1207 24.7841 25.7112 24.6157 26.194 24.2662C26.3898 24.0922 26.5432 23.8757 26.6425 23.6333C26.7419 23.391 26.7845 23.1291 26.7672 22.8677C26.8034 22.3109 26.6296 21.7608 26.28 21.3259C26.0738 21.1336 25.8295 20.9868 25.5629 20.8949C25.2963 20.8031 25.0134 20.7683 24.7325 20.7929H21.9928V24.742H24.5262Z"
        fill={color?color:"black"}
      />
      <path
        d="M35.3761 30.9721C33.6014 33.5515 31.226 35.6607 28.4546 37.1179C25.6833 38.575 22.5992 39.3364 19.4682 39.3364C16.3371 39.3364 13.2531 38.575 10.4817 37.1179C7.71043 35.6607 5.33502 33.5515 3.56025 30.9721H8.28306C9.74033 32.4583 11.4794 33.6389 13.3985 34.4449C15.3176 35.2509 17.3781 35.666 19.4596 35.666C21.5411 35.666 23.6016 35.2509 25.5207 34.4449C27.4398 33.6389 29.1789 32.4583 30.6361 30.9721H35.3761Z"
        fill={color?color:"black"}
      />
      <path
        d="M3.91493 17.9561C3.64968 20.0121 3.79397 22.1002 4.33945 24.1002L1.96659 28.2211C0.641989 25.395 0.0191933 22.2909 0.15112 19.1726C0.283048 16.0542 1.1658 13.0138 2.72436 10.3097C4.28293 7.60553 6.47124 5.3176 9.10336 3.64027C11.7355 1.96294 14.7336 0.945798 17.843 0.675268L15.4759 4.77906C13.4719 5.30953 11.5927 6.23137 9.94661 7.49144C8.30052 8.75151 6.9201 10.3249 5.88489 12.1209C4.84968 13.917 4.18017 15.9001 3.91493 17.9561Z"
        fill={color?color:"black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.2038 15.4174L82.075 17.0681H87.4627L80.1263 4.96875H73.9477L66.6056 17.0681H71.9932L72.8702 15.4174H81.2038ZM77.037 7.49637L79.6391 12.4312H74.4349L77.037 7.49637Z"
        fill={color?color:"black"}
      />
      <path
        d="M64.6856 17.068V4.97438H60.2609V9.41634H50.6777V4.97438H46.2529V17.068H50.6777V12.5572H60.2609V17.068H64.6856Z"
        fill={color?color:"black"}
      />
      <path
        d="M96.2376 9.86913L101.172 4.97438H107.11L98.4442 13.1075V17.068H94.0367V13.1075L85.3705 4.97438H91.3084L96.2376 9.86913Z"
        fill={color?color:"black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.618 15.4174L120.489 17.0681H125.877L118.546 4.96875H112.362L105.02 17.0681H110.408L111.284 15.4174H119.618ZM115.451 7.49637L118.053 12.4312H112.855L115.451 7.49637Z"
        fill={color?color:"black"}
      />
      <path
        d="M123.171 4.97438H141.833V8.11528H134.708V17.068H130.289V8.11528H123.171V4.97438Z"
        fill={color?color:"black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.4555 22.7022C61.6183 22.547 60.7679 22.4741 59.9164 22.4844H46.2581V34.578H60.5068C62.3008 34.578 63.6076 34.1997 64.3756 33.4317C64.6921 33.1213 64.9391 32.7472 65.1002 32.3342C65.2612 31.9212 65.3327 31.4786 65.3098 31.0359V30.864C65.36 30.2302 65.1759 29.6 64.7924 29.093C64.4089 28.5859 63.8528 28.2371 63.2293 28.1128L62.2263 27.8377L62.7994 27.6486C63.1032 27.5518 63.3834 27.3927 63.6223 27.1815C63.8611 26.9703 64.0532 26.7117 64.1864 26.422C64.3757 26.0268 64.4717 25.5935 64.4673 25.1553C64.5221 24.5382 64.3397 23.9233 63.9572 23.4359C63.5435 23.0434 63.0194 22.7873 62.4555 22.7022ZM50.6772 25.4591H58.4721C59.1885 25.4591 59.6184 25.6654 59.7273 26.0896C59.7595 26.1956 59.7769 26.3055 59.7789 26.4163C59.7769 26.5327 59.7596 26.6483 59.7273 26.7602C59.6928 26.866 59.6361 26.9631 59.561 27.0452C59.4859 27.1273 59.394 27.1923 59.2917 27.2359C59.0333 27.3466 58.7527 27.3957 58.4721 27.3792H50.6772V25.4591ZM60.4208 30.9041C60.3814 31.0189 60.3188 31.1244 60.237 31.2142C60.1552 31.3039 60.0558 31.3758 59.9451 31.4257C59.6381 31.5575 59.3041 31.6144 58.9707 31.5919H50.6772V29.4196H58.9478C59.2811 29.3977 59.6149 29.4547 59.9222 29.5858C60.0325 29.6377 60.1315 29.711 60.2131 29.8016C60.2948 29.8921 60.3576 29.998 60.3979 30.1131C60.4354 30.2418 60.4566 30.3747 60.4609 30.5086C60.4579 30.6427 60.4367 30.7757 60.3979 30.9041H60.4208Z"
        fill={color?color:"black"}
      />
      <path
        d="M84.614 27.4765V29.5112H72.3255V31.5975H85.4737V34.5779H67.9065V22.4843H85.3247V25.4533H72.3255V27.4765H84.614Z"
        fill={color?color:"black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.986 32.9215L102.857 34.5779H108.245L100.908 22.4728H94.7525L87.3817 34.5779H92.7751L93.6521 32.9215H101.986ZM97.8189 25.0062L100.415 29.9411H95.2168L97.8189 25.0062Z"
        fill={color?color:"black"}
      />
      <path
        d="M127.338 22.4843V29.7462C127.338 32.2853 126.026 33.8615 123.326 34.5321C121.617 34.9018 119.87 35.0673 118.122 35.025C117.245 35.025 116.351 34.9963 115.451 34.9333C113.83 34.8927 112.244 34.4559 110.832 33.6609C109.536 32.8298 108.911 31.5459 108.911 29.7462V22.4843H113.342V29.1731C113.342 30.1761 113.755 31.4542 115.738 31.8382C116.915 32.0049 118.105 32.049 119.291 31.9701C119.708 31.9532 120.124 31.9091 120.535 31.8382C120.936 31.7616 121.322 31.6244 121.681 31.4313C122.052 31.2288 122.362 30.9322 122.581 30.5716C122.819 30.149 122.936 29.6691 122.919 29.1845V22.4843H127.338Z"
        fill={color?color:"black"}
      />
      <path
        d="M148.848 22.4843H130.186V25.6252H137.311V34.5779H141.73V25.6252H148.848V22.4843Z"
        fill={color?color:"black"}
      />
      <path
        d="M161.808 27.3791L166.743 22.4843H172.681L164.015 30.6174V34.5779H159.601V30.6174L150.941 22.4843H156.879L161.808 27.3791Z"
        fill={color?color:"black"}
      />
    </svg>
  );
};

export default LogoWithTxt;
