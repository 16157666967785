import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appSlice from "./appSlice";
import favoritesSlice from "./favoritesSlice";
import cartSlice from "./cartSlice";
import productsSlice from "./productsSlice";
import categorySlice from "./categorySlice";
import ordersSlice from "./ordersSlice";
import userSlice from "./userSlice";
import { productsApi } from "../api/productsApi2";
import { brandApi } from "../api/brandsApi";
import { promotionsApi } from "../api/promotionsApi";

const userPersistConfig = {
  key: "userSlice",
  storage: storage,
  whitelist: ["accesstoken", "authorized", "refreshtoken", "userInfo"],
};
const cartPersistConfig = {
  key: "cartSlice",
  storage: storage,
  blackList: ["open"],
};

const rootReducer = combineReducers({
  appSlice,
  productsSlice,
  favoritesSlice,
  cartSlice:persistReducer(cartPersistConfig, cartSlice),
  categorySlice,
  ordersSlice,
  userSlice: persistReducer(userPersistConfig, userSlice),
  [productsApi.reducerPath]: productsApi.reducer,
  [brandApi.reducerPath]: brandApi.reducer,
  [promotionsApi.reducerPath]: promotionsApi.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["favoritesSlice"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      productsApi.middleware,
      brandApi.middleware,
      promotionsApi.middleware
    ),
});

// export type AppStore = ReturnType<typeof store>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
export default store;
