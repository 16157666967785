import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { productType } from "../types/productTypes";
import {
  getAllProducts,
  getAllProductsSearch,
  getOneProductThunk,
} from "../api/productsApi";

export interface productsState {
  products: productType[];
  isFetchingProducts: boolean;
  isFetchingMoreProducts: boolean;
  isErrProducts: string;

  isErrSearchedProducts: string;
  isFetchingSearchedProducts: boolean;
  searchedProducts: productType[];
  searchedProductsCount: number;

  oneProduct: any;
  oneProductLoading: boolean;
  oneProductErr: string;
  productsByGoodId: any;

  count: number;
  next: string;
  previous: string;
}

const initialState: productsState = {
  isErrProducts: "",
  isFetchingProducts: false,
  isFetchingMoreProducts: false,
  products: [],

  isErrSearchedProducts: "",
  isFetchingSearchedProducts: false,
  searchedProducts: [],
  searchedProductsCount: 0,

  oneProduct: null,
  oneProductLoading: false,
  oneProductErr: "",
  productsByGoodId: null,

  count: 0,
  next: "",
  previous: "",
};

export const productsSlice = createSlice({
  name: "productsSlice",
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder.addCase(getAllProducts.pending, (state, action) => {
      console.log("Products", action);
      state.isErrProducts = "";
      if (action.meta.arg.addMore) {
        state.isFetchingMoreProducts = true;
      }else{
        state.isFetchingProducts = true;
      }
    });
    builder.addCase(
      getAllProducts.rejected,
      (state, action: PayloadAction<any>) => {
        state.isErrProducts = action.payload;
        state.isFetchingProducts = false;
        state.isFetchingMoreProducts = false;
        state.products = [];
      }
    );
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.isErrProducts = "";
      state.isFetchingProducts = false;
      state.isFetchingMoreProducts = false;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
      if (action.payload.addMore) {
        state.products = [...state.products, ...action.payload.results];
      } else {
        state.products = action.payload.results;
      }
    });

    builder.addCase(getAllProductsSearch.pending, (state, action) => {
      state.isErrSearchedProducts = "";
      if (action.meta.arg.addMore) {
        state.isFetchingMoreProducts = true;
      }else{
        state.isFetchingSearchedProducts = true;
      }
    });
    builder.addCase(
      getAllProductsSearch.rejected,
      (state, action: PayloadAction<any>) => {
        state.isErrSearchedProducts = action.payload;
        state.isFetchingSearchedProducts = false;
        state.isFetchingMoreProducts = false;
        state.searchedProducts = [];
        state.searchedProductsCount = 0;
      }
    );
    builder.addCase(getAllProductsSearch.fulfilled, (state, action) => {
      state.isErrSearchedProducts = "";
      state.isFetchingSearchedProducts = false;
      state.isFetchingMoreProducts = false;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
      if (action.payload.addMore) {
        state.searchedProducts = [
          ...state.searchedProducts,
          ...action.payload.results,
        ];
      } else {
        state.searchedProducts = action.payload.results;
      }
      state.searchedProductsCount = action.payload.count;
    });
    builder.addCase(getOneProductThunk.pending, (state) => {
      state.oneProductErr = "";
      state.oneProductLoading = true;
      state.oneProduct = null;
      state.productsByGoodId = null;
    });
    builder.addCase(
      getOneProductThunk.rejected,
      (state, action: PayloadAction<any>) => {
        state.oneProductErr = action.payload;
        state.oneProductLoading = false;
        state.oneProduct = null;
        state.productsByGoodId = null;
      }
    );
    builder.addCase(getOneProductThunk.fulfilled, (state, action) => {
      state.oneProductErr = "";
      state.oneProductLoading = false;
      state.oneProduct = action.payload.product;
      state.productsByGoodId = action.payload.products.results;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
} = productsSlice.actions;

export default productsSlice.reducer;
