import React from "react";
import { Route, Routes } from "react-router";
import Header from "./components/ui/Header/Header";
import Footer from "./components/ui/Footer/Footer";
import { useAppDispatch, useAppSelector } from "./store/store";
import { getToFavsApi } from "./api/favoritesApi";
import PageNotFound from "./pages/PageNotFound";
const PromotionPage = React.lazy(() => import("./pages/PromotionPage"));
const BrandsPage = React.lazy(() => import("./pages/BrandsPage"));
const MainPage = React.lazy(() => import("./pages/MainPage"));
const ProductInnerPage = React.lazy(() => import("./pages/ProductInnerPage"));
const GiftCardsPage = React.lazy(() => import("./pages/GiftCardsPage"));
const FavoritesPage = React.lazy(() => import("./pages/FavoritesPage"));
const ProfilePage = React.lazy(() => import("./pages/ProfilePage/ProfilePage"));
const ProfileUserPage = React.lazy(
  () => import("./pages/ProfilePage/ProfileUserPage")
);
const ProfileFavoritesPage = React.lazy(
  () => import("./pages/ProfilePage/ProfileFavoritesPage")
);
const OrderHistoryPage = React.lazy(
  () => import("./pages/ProfilePage/OrderHistoryPage/OrderHistoryPage")
);
const OrderHistoryInnerPage = React.lazy(
  () => import("./pages/ProfilePage/OrderHistoryPage/OrderHistoryInnerPage")
);
const MakeOrderPage = React.lazy(
  () => import("./pages/MakeOrderPage/MakeOrderPage")
);
const OrderDeliveryPage = React.lazy(
  () => import("./pages/MakeOrderPage/OrderDeliveryPage")
);
// const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const OrderSelfPage = React.lazy(
  () => import("./pages/MakeOrderPage/OrderSelfPage")
);
const MarketsPage = React.lazy(() => import("./pages/MarketsPage"));
const SearchedPage = React.lazy(() => import("./pages/SearchedPage"));
const ProductsPage = React.lazy(() => import("./pages/ProductsPage"));

function App() {
  const { authorized } = useAppSelector((state) => state.userSlice);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (authorized) {
      dispatch(getToFavsApi());
    }
  }, [dispatch, authorized]);

  return (
    <>
      <Header />
      <main className="main min-h-[110vh]">
        <Routes>
          <Route
            path={"/"}
            element={
              <React.Suspense fallback={<div>...</div>}>
                <MainPage />
              </React.Suspense>
            }
          ></Route>
          <Route
            path={"/promotion"}
            element={
              <React.Suspense fallback={<div>...</div>}>
                <PromotionPage />
              </React.Suspense>
            }
          ></Route>
          <Route
            path={"/brands"}
            element={
              <React.Suspense fallback={<div>...</div>}>
                <BrandsPage />
              </React.Suspense>
            }
          ></Route>
          <Route
            // path={"/product/?itemId=:itemId&goodId=:goodId"}
            path={"/product/:itemId/:goodId"}
            element={
              <React.Suspense fallback={<div>...</div>}>
                <ProductInnerPage />
              </React.Suspense>
            }
          ></Route>
          <Route
            path={"/gifts"}
            element={
              <React.Suspense fallback={<div>...</div>}>
                <GiftCardsPage />
              </React.Suspense>
            }
          ></Route>
          <Route
            path={"/favorites"}
            element={
              <React.Suspense fallback={<div>...</div>}>
                <FavoritesPage />
              </React.Suspense>
            }
          ></Route>
          {authorized && (
            <Route
              path={"/profile"}
              element={
                <React.Suspense fallback={<div>...</div>}>
                  <ProfilePage />
                </React.Suspense>
              }
            >
              <Route
                path={"me"}
                element={
                  <React.Suspense fallback={<div>...</div>}>
                    <ProfileUserPage />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path={"favorites"}
                element={
                  <React.Suspense fallback={<div>...</div>}>
                    <ProfileFavoritesPage />
                  </React.Suspense>
                }
              ></Route>
              <Route
                path={"orders"}
                element={
                  <React.Suspense fallback={<div>...</div>}>
                    <OrderHistoryPage />
                  </React.Suspense>
                }
              ></Route>
            </Route>
          )}
          <Route
            path={"orders/:orderId"}
            element={
              <React.Suspense fallback={<div>...</div>}>
                <OrderHistoryInnerPage />
              </React.Suspense>
            }
          ></Route>
          <Route
            path={"/order"}
            element={
              <React.Suspense fallback={<div>...</div>}>
                <MakeOrderPage />
              </React.Suspense>
            }
          >
            <Route
              index
              element={
                <React.Suspense fallback={<div>...</div>}>
                  <OrderDeliveryPage />
                </React.Suspense>
              }
            ></Route>
            <Route
              path={"self"}
              element={
                <React.Suspense fallback={<div>...</div>}>
                  <OrderSelfPage />
                </React.Suspense>
              }
            ></Route>
          </Route>
          <Route
            path={"/markets"}
            element={
              <React.Suspense fallback={<div>...</div>}>
                <MarketsPage />
              </React.Suspense>
            }
          ></Route>
          <Route
            path={"/productsSearch"}
            element={
              <React.Suspense fallback={<div>...</div>}>
                <SearchedPage />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/products"
            element={
              <React.Suspense fallback={<div>...</div>}>
                <ProductsPage />
              </React.Suspense>
            }
          />
          <Route
            path="/products/:brandId"
            element={
              <React.Suspense fallback={<div>...</div>}>
                <ProductsPage />
              </React.Suspense>
            }
          />
          <Route
            path="/products/bycategory/:categoryId"
            element={
              <React.Suspense fallback={<div>...</div>}>
                <ProductsPage />
              </React.Suspense>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
