import React from "react";
import { IconProps } from "../../types/appTypes";

const FavoriteIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "25"}
      height={height ? height : "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M23.5 8.16241C23.5 5.28789 20.9346 2.95703 17.7702 2.95703C15.4052 2.95703 13.3739 4.25953 12.5 6.11843C11.6261 4.25953 9.59478 2.95703 7.22856 2.95703C4.06667 2.95703 1.5 5.28789 1.5 8.16241C1.5 16.5142 12.5 22.0434 12.5 22.0434C12.5 22.0434 23.5 16.5142 23.5 8.16241Z"
        stroke={color ? color : "#070707"}
        strokeWidth="2.27295"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FavoriteIcon;
