import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { giftCardOrderType, mainSlider } from "../types/appTypes";
import {
  checkCode,
  checkPhone,
  checkPhoneGiftCard,
  getAllFinishesType,
  getAllSkinsType,
  getImgGiftCards,
  getMainSlider,
  orderImgGiftCards,
} from "../api/appApi";
import { ISkinType } from "../types/productTypes";
import { notification } from "antd";

export interface AppState {
  mainSlider: mainSlider[] | null;
  isFetchingMainSlider: boolean;
  errMainSlider: string;

  imgGiftCards: mainSlider[] | null;
  isFetchingImgGiftCards: boolean;
  errImgGiftCards: string;

  imgGiftCardsOrdered: giftCardOrderType | null;
  isFetchingImgGiftCardsOrder: boolean;
  errImgGiftCardsOrder: string;

  skinsType: ISkinType[] | null;
  errSkinType: string;
  fetchingSkinType: boolean;

  finishesType: ISkinType[] | null;
  errFinishesType: string;
  fetchingFinishesType: boolean;

  orderImgGiftCards: any;
  errOrderImgGiftCards: string;
  fetchingOrderImgGiftCards: boolean;

  customer: string;
  phone: string;
  address: string;
  amount: any;
  image: any;
  nominal: any;

  visibleOrderGiftCardModal: boolean;
  checkCodeModal: boolean;

  checkPhoneRes: any;
  errCheckPhoneRes: string;
  fetchingCheckPhoneRes: boolean;
  checkCodeRes: any;
  errcheckCodeRes: string;
  fetchingcheckCodeRes: boolean;
  visibleConfirmModal: boolean;
  thanksModalGiftCard: boolean;
  code: string;

  giftImg: number | string;

  burgerOpened: boolean;
}

const initialState: AppState = {
  errMainSlider: "",
  isFetchingMainSlider: false,
  mainSlider: null,
  errImgGiftCards: "",
  imgGiftCards: null,
  isFetchingImgGiftCards: false,
  errImgGiftCardsOrder: "",
  imgGiftCardsOrdered: null,
  isFetchingImgGiftCardsOrder: false,

  errSkinType: "",
  fetchingSkinType: false,
  skinsType: null,
  errFinishesType: "",
  fetchingFinishesType: false,
  finishesType: null,

  errOrderImgGiftCards: "",
  fetchingOrderImgGiftCards: false,
  orderImgGiftCards: null,

  address: "",
  amount: "",
  customer: "",
  image: "",
  nominal: "",
  phone: "",

  visibleOrderGiftCardModal: false,

  checkCodeModal: false,

  checkPhoneRes: null,
  errCheckPhoneRes: "",
  fetchingCheckPhoneRes: false,
  checkCodeRes: null,
  errcheckCodeRes: "",
  fetchingcheckCodeRes: false,
  visibleConfirmModal: false,
  thanksModalGiftCard: false,

  code: "",
  giftImg: "",
  burgerOpened: false
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAddressAC(state, action: PayloadAction<string>) {
      state.address = action.payload;
    },
    setAmountAC(state, action: PayloadAction<string>) {
      state.amount = action.payload;
    },
    setCustomerAC(state, action: PayloadAction<string>) {
      state.customer = action.payload;
    },
    setImageAC(state, action: PayloadAction<string>) {
      state.image = action.payload;
    },
    setNominalAC(state, action: PayloadAction<string>) {
      state.nominal = action.payload;
    },
    setPhoneAC(state, action: PayloadAction<string>) {
      state.phone = action.payload;
    },
    setVisibleOrderGiftCardModalAC(state, action: PayloadAction<boolean>) {
      state.visibleOrderGiftCardModal = action.payload;
    },
    checkCodeModalAC(state, action: PayloadAction<boolean>) {
      state.checkCodeModal = action.payload;
    },
    setVisibleConfirmModalAC(state, action: PayloadAction<boolean>) {
      state.visibleConfirmModal = action.payload;
    },
    setCodeAC(state, action: PayloadAction<string>) {
      state.code = action.payload;
    },
    setGiftImgAC(state, action: PayloadAction<number | string>) {
      state.giftImg = action.payload;
    },
    setThanksModalGiftCardAC(state, action) {
      state.thanksModalGiftCard = action.payload;
    },
    setBurgerOpened(state,action: PayloadAction<boolean>) {
      state.burgerOpened = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getMainSlider.pending, (state) => {
      state.errMainSlider = "";
      state.isFetchingMainSlider = true;
    });
    builder.addCase(
      getMainSlider.rejected,
      (state, action: PayloadAction<any>) => {
        state.errMainSlider = action.payload;
        state.isFetchingMainSlider = false;
        state.mainSlider = null;
      }
    );
    builder.addCase(getMainSlider.fulfilled, (state, action) => {
      state.errMainSlider = "";
      state.isFetchingMainSlider = false;
      state.mainSlider = action.payload;
    });

    builder.addCase(getImgGiftCards.pending, (state) => {
      state.errMainSlider = "";
      state.isFetchingImgGiftCards = true;
    });
    builder.addCase(
      getImgGiftCards.rejected,
      (state, action: PayloadAction<any>) => {
        state.errImgGiftCards = action.payload;
        state.isFetchingImgGiftCards = false;
        state.imgGiftCards = null;
      }
    );
    builder.addCase(
      getImgGiftCards.fulfilled,
      (state, action: PayloadAction<mainSlider[]>) => {
        state.errImgGiftCards = "";
        state.isFetchingImgGiftCards = false;
        state.imgGiftCards = action.payload;
      }
    );
    // orderImgGiftCards
    builder.addCase(orderImgGiftCards.pending, (state) => {
      state.errOrderImgGiftCards = "";
      state.fetchingOrderImgGiftCards = true;
    });
    builder.addCase(
      orderImgGiftCards.rejected,
      (state, action: PayloadAction<any>) => {
        state.errOrderImgGiftCards = action.payload;
        state.fetchingOrderImgGiftCards = false;
        state.orderImgGiftCards = null;
        notification.error({
          message: "Ошибка при заказе подарочныз кард!",
          description: action.payload,
        });
        state.visibleConfirmModal = false;
        state.visibleOrderGiftCardModal = false;
      }
    );
    builder.addCase(
      orderImgGiftCards.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.errOrderImgGiftCards = "";
        state.fetchingOrderImgGiftCards = false;
        state.orderImgGiftCards = action.payload;
        state.visibleConfirmModal = false;
        state.visibleOrderGiftCardModal = false;
        state.thanksModalGiftCard = true;
      }
    );
    // skinsType
    builder.addCase(getAllSkinsType.pending, (state) => {
      state.errSkinType = "";
      state.fetchingSkinType = true;
    });
    builder.addCase(
      getAllSkinsType.rejected,
      (state, action: PayloadAction<any>) => {
        state.errSkinType = action.payload;
        state.fetchingSkinType = false;
        state.skinsType = null;
      }
    );
    builder.addCase(
      getAllSkinsType.fulfilled,
      (state, action: PayloadAction<ISkinType[]>) => {
        state.errSkinType = "";
        state.fetchingSkinType = false;
        state.skinsType = action.payload;
      }
    );
    // finishesType
    builder.addCase(getAllFinishesType.pending, (state) => {
      state.errFinishesType = "";
      state.fetchingFinishesType = true;
    });
    builder.addCase(
      getAllFinishesType.rejected,
      (state, action: PayloadAction<any>) => {
        state.errFinishesType = action.payload;
        state.fetchingFinishesType = false;
        state.finishesType = null;
      }
    );
    builder.addCase(
      getAllFinishesType.fulfilled,
      (state, action: PayloadAction<ISkinType[]>) => {
        state.errFinishesType = "";
        state.fetchingFinishesType = false;
        state.finishesType = action.payload;
      }
    );
    // checkCode
    builder.addCase(checkCode.pending, (state) => {
      state.errcheckCodeRes = "";
      state.fetchingcheckCodeRes = true;
    });
    builder.addCase(checkCode.rejected, (state, action: PayloadAction<any>) => {
      state.errcheckCodeRes = action.payload;
      state.fetchingcheckCodeRes = false;
      state.checkCodeRes = null;
      notification.error({
        message: action.payload,
      });
    });
    builder.addCase(
      checkCode.fulfilled,
      (state, action: PayloadAction<ISkinType[]>) => {
        state.errcheckCodeRes = "";
        state.fetchingcheckCodeRes = false;
        state.checkCodeRes = action.payload;
        state.checkCodeModal = false;
      }
    );
    // checkPhone
    builder.addCase(checkPhone.pending, (state) => {
      state.errCheckPhoneRes = "";
      state.fetchingCheckPhoneRes = true;
    });
    builder.addCase(
      checkPhone.rejected,
      (state, action: PayloadAction<any>) => {
        state.errCheckPhoneRes = action.payload;
        state.fetchingCheckPhoneRes = false;
        state.checkPhoneRes = null;
        notification.error({
          message: action.payload,
        });
      }
    );
    builder.addCase(
      checkPhone.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.errCheckPhoneRes = "";
        state.fetchingCheckPhoneRes = false;
        state.checkPhoneRes = action.payload.data;
        if (action.payload.is_send_sms) {
          state.visibleConfirmModal = true;
        } else {
          state.visibleConfirmModal = false;
        }
        state.visibleOrderGiftCardModal = false;
      }
    );
    // checkPhoneGiftCard
    builder.addCase(checkPhoneGiftCard.pending, (state) => {
      state.errCheckPhoneRes = "";
      state.fetchingCheckPhoneRes = true;
    });
    builder.addCase(
      checkPhoneGiftCard.rejected,
      (state, action: PayloadAction<any>) => {
        state.errCheckPhoneRes = action.payload;
        state.fetchingCheckPhoneRes = false;
        state.checkPhoneRes = null;
        notification.error({
          message: action.payload,
        });
      }
    );
    builder.addCase(
      checkPhoneGiftCard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.errCheckPhoneRes = "";
        state.fetchingCheckPhoneRes = false;
        state.visibleOrderGiftCardModal = false;
        state.thanksModalGiftCard = true;
        state.visibleOrderGiftCardModal = false;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setAddressAC,
  setAmountAC,
  setCustomerAC,
  setImageAC,
  setNominalAC,
  setPhoneAC,
  setVisibleOrderGiftCardModalAC,
  checkCodeModalAC,
  setVisibleConfirmModalAC,
  setCodeAC,
  setGiftImgAC,
  setThanksModalGiftCardAC,
  setBurgerOpened
} = appSlice.actions;

export default appSlice.reducer;
//getMainSlider
