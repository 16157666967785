import React from "react";

const BoxWithTickIcon:React.FC = ():JSX.Element => {
  return (
    <svg
      width="123"
      height="101"
      viewBox="0 0 123 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.2812 29.7343C67.1769 34.0712 56.6144 38.1919 54.8092 38.8917L51.5268 40.1638V67.6427C51.5268 82.7561 51.5806 95.1216 51.6465 95.1216C51.7976 95.1216 72.9266 86.9204 73.0351 86.8196C73.0792 86.7787 72.8381 85.8683 72.4995 84.7964C71.5723 81.8616 71.2581 79.157 71.414 75.4535C71.7189 68.2003 74.535 61.919 79.7256 56.9154C83.0553 53.7057 86.1801 51.794 90.2958 50.4486C92.5791 49.7022 96.1327 49.0447 97.8839 49.0447H99.0901V35.4198C99.0901 24.6708 99.0247 21.8007 98.7804 21.822C98.6102 21.8368 89.3854 25.3974 78.2812 29.7343Z"
        fill="black"
      />
      <path
        d="M59.1444 22.3506C46.5072 27.2724 36.1679 31.3626 36.1681 31.4399C36.1684 31.62 49.0979 36.658 49.5651 36.66C50.0031 36.6617 96.1183 18.7455 96.1149 18.5748C96.1112 18.3778 83.012 13.3706 82.543 13.3872C82.3109 13.3951 71.7813 17.4288 59.1444 22.3506Z"
        fill="#070707"
      />
      <path
        d="M26.0785 9.48005C13.3764 14.414 2.98311 18.5064 2.98212 18.5745C2.98138 18.6427 6.62962 20.1161 11.0897 21.8487L19.1987 24.9993L42.5589 15.9202C56.9676 10.3201 65.7906 6.76894 65.5833 6.65301C64.8619 6.24922 49.8136 0.491092 49.5032 0.50001C49.3218 0.505213 38.7809 4.54635 26.0785 9.48005Z"
        fill="#070707"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.0424 53.3997C83.9053 55.6585 76.5456 63.8176 75.3109 74.1655C74.2806 82.8017 78.4902 91.7377 85.8717 96.5834C97.8083 104.42 113.92 100.075 120.255 87.3108C123.635 80.5001 123.593 72.754 120.137 65.8732C117.294 60.2112 111.881 55.7207 105.655 53.8573C103.741 53.2845 102.882 53.1783 99.5855 53.1052C96.7763 53.043 95.2887 53.122 94.0424 53.3997ZM111.85 65.2559C113.014 65.7419 113.704 66.9441 113.705 68.4874L113.706 69.7226L104.351 79.0777L94.9954 88.433L93.7602 88.4318C93.0109 88.431 92.2595 88.2604 91.8493 87.9983C91.2008 87.5833 84.3792 79.1916 83.443 77.657C81.8566 75.0559 84.6643 71.8213 87.5458 72.9306C88.3693 73.2477 89.0465 73.9317 91.1212 76.5422C92.5288 78.3134 93.7711 79.7626 93.8816 79.7626C93.9924 79.7626 97.2051 76.6463 101.021 72.8375C104.837 69.0287 108.333 65.6849 108.789 65.4067C109.767 64.8102 110.677 64.7654 111.85 65.2559Z"
        fill="#F5016B"
      />
      <path
        d="M1.04367 77.105C3.61308 78.1321 47.3802 95.1216 47.4567 95.1216C47.5154 95.1216 47.5632 82.7599 47.5632 67.6511V40.1804L46.5104 39.757C43.7815 38.6598 33.4518 34.6767 33.3354 34.6767C33.263 34.6767 33.1741 37.3786 33.1377 40.6808C33.08 45.8914 33.0203 46.6946 32.6846 46.7592C32.4718 46.8001 30.5363 45.3328 28.3834 43.4986C26.2304 41.6642 24.3422 40.2089 24.1871 40.2644C22.651 40.8136 17.3792 42.3561 17.038 42.3561C16.6261 42.3561 16.5976 41.8936 16.5971 35.234L16.5968 28.1119L8.46948 24.9535C3.99953 23.2164 0.265318 21.795 0.171183 21.795C0.0770473 21.795 4.77621e-06 33.8956 4.77621e-06 48.6855L2.35021e-06 49.5642C-0.000102204 74.6763 -0.000109971 76.5413 0.801854 76.998C0.866209 77.0346 0.935728 77.0622 1.01083 77.092L1.04367 77.105Z"
        fill="#070707"
      />
    </svg>
  );
};

export default BoxWithTickIcon;
