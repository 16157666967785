import React from "react";
import { IconProps } from "../../types/appTypes";

const ProfileIcon: React.FC<IconProps> = ({
  className,
  color,
  height,
  width,
  fill
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width ? width : "26"}
      height={height ? height : "25"}
      viewBox="0 0 26 25"
      fill={fill?fill:"none"}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.7002 5.68774C17.7002 6.81692 17.2512 7.89985 16.452 8.6983C15.6527 9.49675 14.5687 9.94531 13.4384 9.94531C12.3081 9.94531 11.2241 9.49675 10.4249 8.6983C9.62566 7.89985 9.17665 6.81692 9.17665 5.68774C9.17665 4.55857 9.62566 3.47564 10.4249 2.67719C11.2241 1.87874 12.3081 1.43018 13.4384 1.43018C14.5687 1.43018 15.6527 1.87874 16.452 2.67719C17.2512 3.47564 17.7002 4.55857 17.7002 5.68774ZM6.31043 22.0198C5.5874 21.8626 5.18692 21.0852 5.47868 20.4053L5.97471 19.2492C6.92706 17.0295 8.71363 15.2731 10.9492 14.3587V14.3587C12.5445 13.7062 14.3323 13.7062 15.9277 14.3587V14.3587C18.1632 15.2731 19.9498 17.0295 20.9022 19.2492L21.3982 20.4053C21.6899 21.0852 21.2895 21.8626 20.5664 22.0198V22.0198C15.8693 23.041 11.0076 23.041 6.31043 22.0198V22.0198Z"
        stroke={color ? color : "#070707"}
        strokeWidth="2.27295"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProfileIcon;
